import React, { Component } from 'react';

import './PaymentPlanStructure.scss';
import MilestoneCheckbox from '../milestone-checkbox/MilestoneCheckbox';

interface CustomProps {
  course: any;
  selectedMilestones?: any[];
  onMilestoneSelection?: (milestone: any, selected: boolean) => void;
  totalForMilestones?: number;
}
class PaymentPlanStructure extends Component<CustomProps> {
  constructor(props: CustomProps) {
    super(props);
    this.renderMilestone = this.renderMilestone.bind(this);
  }

  renderMilestone(milestone: any, index: number) {
    return (
      <MilestoneCheckbox
        key={milestone._id}
        id={milestone._id}
        label={milestone.name.en}
        disabled={index === 0}
        selected={
          (this.props.selectedMilestones || []).filter(
            (ml) => ml._id === milestone._id
          ).length > 0
        }
        onChange={(value) => {
          if (this.props.onMilestoneSelection) {
            this.props.onMilestoneSelection(milestone, value);
          }
        }}
        gap="sm"
        totalAmount={
          milestone.amount.totalAmount - milestone.amount.totalDiscount
        }
      />
    );
  }

  render() {
    return (
      <div className="p-6 -mt-5 bg-slate-50">
        {this.props.course &&
          this.props.course.milestones &&
          this.props.course.milestones.map((ml: any, i: number) =>
            this.renderMilestone(ml, i)
          )}
      </div>
    );
  }
}

// PaymentPlanStructure.propTypes = {
//   course: PropTypes.shape({
//     milestones: PropTypes.arrayOf(PropTypes.shape({})),
//   }).isRequired,
//   selectedMilestones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   onMilestoneSelection: PropTypes.func.isRequired,
// };

export default PaymentPlanStructure;
