import { getUTCStartOfDate } from '../../utils/date-utils';

export default function(formData: any) {
  const {
    gender,
    name,
    countryCode,
    startingCode,
    number: phoneNumber,
    email,
    password,
    gearType,
    licenseTypeId,
    licenseTypeCategory,
    language,
    nationality,
    hasResidencyVisa,
    dob,
    trainingBranch,
  } = formData;

  const finalData = {
    gender,
    name,
    email,
    password,
    nationality,
    dob: getUTCStartOfDate(dob),
    phones: {
      firstNumber: {
        countryCode,
        startingCode,
        numberType: 'home',
        number: phoneNumber.toString(),
        NumberCode: `${countryCode}${startingCode}${phoneNumber}`,
        isPrimary: true,
      },
    },
    studentDetails: {
      // emirate: 'dubai',
      gearType,
      language,
      hasResidencyVisa,
      requiredLicenseId: licenseTypeId,
      requiredLicenseCategory: licenseTypeCategory,
    },
    trainingBranchId: trainingBranch,
  };
  return finalData;
}

// function findGenderFromTitle(title: string) {
//   let gender = '';
//   switch (title.toLowerCase()) {
//     case 'mr':
//       gender = 'male';
//       break;
//     case 'mrs':
//     case 'miss':
//     case 'ms.':
//       gender = 'female';
//       break;
//     default:
//       gender = 'male';
//   }
//   return gender;
// }
