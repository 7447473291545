import React, { Component } from 'react';

import './PaymentSummary.scss';

interface CustomProps {
  summary: any;
  showBackground?: boolean;
  referralAmount?:any;
  
}

class PaymentSummary extends Component<CustomProps> {
  // eslint-disable-next-line class-methods-use-this
  renderHeader() { 
    return <p className="text-base font-semibold pb-4">Payment Details</p>;
  }


  renderSummarySubtotal() {
    return (
      <div className="grid grid-cols-12 col-span-6 py-2">
        <span className="text-sm text-muted col-span-6">
          Total W/O Discount
        </span>
        <span className="text-sm col-span-6 text-right">
          AED {this.props.summary.eds.totalAmount.toFixed(2)}
        </span>
      </div>
    );
  }

  renderSummaryDiscount() {
    return (
      <li className="grid grid-cols-12 col-span-6 py-2">
        <span className="text-sm text-muted col-span-6">Discount</span>
        <span className="text-sm col-span-6 text-right">
          {this.props.summary.totalDiscount > 0 ? '' : ''} AED{' '}
          {this.props.summary.totalDiscount.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryRtaAmount() {
    return (
      <li className="grid grid-cols-12 col-span-6 py-2">
        <span className="text-sm text-muted col-span-6">RTA Amount</span>
        <span className="text-sm col-span-6 text-right">
          AED {this.props.summary.rta.totalAmount.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryNetAmount() {
    let netPay = 0;
    if (
      this.props.summary &&
      this.props.summary.eds &&
      this.props.summary.eds.totalAmount
    ) {
      netPay = this.props.summary.eds.totalAmount;
    }
    if (this.props.summary && this.props.summary.totalDiscount) {
      netPay -= this.props.summary.totalDiscount;
    }
    return (
      <li className="grid grid-cols-12 col-span-6 py-2">
        <span className="text-sm text-muted col-span-6">Net Amount</span>
        <span className="text-sm col-span-6 text-right">
          AED {netPay.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryWithOutVat() {
    let netPay = 0;
    if (this.props.summary && this.props.summary.totalAmount) {
      netPay =
        this.props.summary.totalAmount - this.props.summary.totalDiscount;
    }
    return (
      <li className="grid grid-cols-12 col-span-6 py-2">
        <span className="text-sm text-muted col-span-6">
          Subtotal without VAT
        </span>
        <span className="text-sm col-span-6 text-right">
          AED {netPay.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryTax() {
    return (
      <li className="grid grid-cols-12 col-span-6 py-2">
        <span className="text-sm text-muted col-span-6">
          5% VAT is applicable for Ecodrive Fees
        </span>
        <span className="text-sm col-span-6 text-right">
          AED {this.props.summary.totalTax.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryAmountPayable() {
    let netPay =
      this.props.summary.totalAmount +
      this.props.summary.totalTax -
      this.props.summary.totalDiscount;
      if (this.props.summary && this.props.referralAmount) {
        netPay -= this.props.referralAmount;
      }
  
    return (
      <li className="grid grid-cols-12 col-span-6 py-3 border-t md:border-b mt-6">
        <span className="text-base font-thin col-span-6">Amount Payable</span>
        <span className="text-base font-semibold col-span-6 text-right">
          AED {netPay.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryReferral() {
    let netReferral = 0;
    if (this.props.referralAmount) {
      netReferral = this.props.referralAmount;
    }

    return (

      <li className="grid grid-cols-12 col-span-6 py-2 ">
      <span className="text-sm text-muted col-span-6">Referral Amount</span>
      <span className="text-sm col-span-6 text-right text-danger">
      -   {this.props.summary && netReferral > 0 ? '' : ''} AED{' '}
           {this.props.summary ? netReferral.toFixed(2) : 0}
      </span>
    </li>

    );
  }

  render() {

    return (
      <div
        className={`pt-[30px] md:pt-[60px]
        //  ${this.props.showBackground ? 'bg-white p-lg-4 p-3 rounded' : ''}
        `}
      >
        {this.renderHeader()}
        {this.renderSummarySubtotal()}
        {this.renderSummaryDiscount()}
        {this.renderSummaryNetAmount()}
        {this.renderSummaryRtaAmount()}
        {this.renderSummaryWithOutVat()}
        {this.renderSummaryTax()}
        { this.props?.referralAmount > 0 && 
              this.renderSummaryReferral()
          }
        {this.renderSummaryAmountPayable()}
      </div>
    );
  }
}

// PaymentSummary.propTypes = {
//   summary: PropTypes.shape({
//     totalAmount: PropTypes.number.isRequired,
//     totalTax: PropTypes.number.isRequired,
//     totalDiscount: PropTypes.number.isRequired,
//     rta: PropTypes.shape({
//       totalAmount: PropTypes.number.isRequired,
//     }).isRequired,
//     eds: PropTypes.shape({
//       totalAmount: PropTypes.number.isRequired,
//     }).isRequired,
//   }).isRequired,
//   showBackground: PropTypes.bool,
// };

// PaymentSummary.defaultProps = {
//   showBackground: false,
// };

export default PaymentSummary;
