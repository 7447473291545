import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import User from '../../models/user';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import Popup from '../../components/popup';
// import Avatar from '../../components/avatar';
import ClickLink from '../../components/click-link';
import { Notification } from '../../models/userNotification';
import { State } from '../../store/interfaces';

import { actions as notificationActions } from '../../store/reducers/notification';
import { withScrollHandler } from '../../components/scroll';
import { func } from 'prop-types';
import { useScrollPosition } from '../../hooks/scroll';
import { ScrollDirection } from '../../models/scroll-directions';

interface CustomProps {
  notifications?: Notification[];
  notifyId?: string;
}

const perPage = 5;

const UserHeaderNotifications: React.SFC<CustomProps> = (
  props: CustomProps
) => {
  const [page, setPage] = useState(1);

  const { isLoading, user } = useSelector((state: State) => state.authUser);

  const { list, unReadCount } = useSelector(
    (state: State) => state.notification
  );

  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    if (!user) return;

    const request = {
      pagination: { perPage, page },
    };
    if (!isLoading) {
      Promise.all([
        dispatch(notificationActions.getList(request)),
        dispatch(notificationActions.getUnreadCount()),
      ]);
    }
  }, [page, user, isLoading]);

  const handleClick = () => {
    if (unReadCount) {
      dispatch(notificationActions.denotify());
    }
  };

  const handleScroll = (direction: ScrollDirection) => {
    if (direction === ScrollDirection.PageDown) {
      if (!isLoading) {
        if (list.length >= page * perPage) setPage(page + 1);
      }
    }
  };
  useScrollPosition(handleScroll, [handleScroll], ref);
  return (
    <>
      <div
        id={props.notifyId || 'notificationIcon'}
        className="relative ltr:pr-2.5 rtl:pl-2.5 ltr:xl:mr-1 rtl:xl:ml-1 cursor-pointer"
        onClick={handleClick}
      >
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none">
          <path
            d="M8.75003 20C10.1297 20 11.2489 18.8809 11.2489 17.5H6.2512C6.2512 18.8809 7.37034 20 8.75003 20ZM17.1637 14.152C16.409 13.341 14.9969 12.1211 14.9969 8.125C14.9969 5.08984 12.8688 2.66016 9.99925 2.06406V1.25C9.99925 0.559766 9.43987 0 8.75003 0C8.06018 0 7.50081 0.559766 7.50081 1.25V2.06406C4.63128 2.66016 2.50315 5.08984 2.50315 8.125C2.50315 12.1211 1.09104 13.341 0.336355 14.152C0.10198 14.4039 -0.00192612 14.7051 2.70074e-05 15C0.00432388 15.6406 0.507058 16.25 1.25393 16.25H16.2461C16.993 16.25 17.4961 15.6406 17.5 15C17.502 14.7051 17.3981 14.4035 17.1637 14.152Z"
            fill="#151817"
          />
        </svg>
        {unReadCount > 0 && (
          <span className="w-4 h-4 min-w-[16px] leading-5 inline-flex justify-center rounded-full bg-[#FF5E57] text-[10px] absolute -top-2 ltr:right-0 rtl:left-0">
            {unReadCount}
          </span>
        )}
      </div>
      <Popup
        target={props.notifyId || 'notificationIcon'}
        cuePos="pos1"
        align="right"
        right="-20"
        top={20}
      >
        <div className="UserHeader__notification-box" ref={ref}>
          <div className="UserHeader__notification-header">Notifications</div>
          <ul className="UserHeader__notification-list">
            {list &&
              list.map((notification) => (
                <li
                  className="UserHeader__notification-item"
                  key={notification.timestamp}
                >
                  <div className="UserHeader__notification-item-icon">
                    <i className="icon-notification-outline"></i>
                  </div>
                  {/* <Avatar
                    src={notification.user.image}
                    name={notification.user.name}
                  /> */}
                  <div className="UserHeader__notification-message">
                    {notification.message}
                    <span className="UserHeader__notification-time">
                      {formatDistanceStrict(
                        new Date(notification.timestamp),
                        new Date()
                      )}
                    </span>
                  </div>
                </li>
              ))}
          </ul>
          <div className="UserHeader__notification-footer">
            {/* <ClickLink link="" primary>
                View All Notifications
              </ClickLink> */}
          </div>
        </div>
      </Popup>
    </>
  );
};

export default UserHeaderNotifications;
