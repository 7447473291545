import React, { Component } from 'react';
import { TextInput } from '../../form-group';
import SelectInput from '../../form-group/SelectInput';
// import PropTypes from 'prop-types';

import './PromoCode.scss';

interface CustomProps {
  promotion?: any;
  amount?: number;
  removeButtonText?: string;
  showRemoveButton?: boolean;
  showPromoCodeBox?: boolean;
  referralApplyText?: string;
  course?: any;
  referralCode?: any;
  onClick: (value: string, referralType: string) => void;
  onRemoveReferral: (value: string) => void;
  referralType: any;
}

interface CustomState {
  value: any;
  referralType: any;
}

class PromoCode extends Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    referralCode: '',
  };

  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: props.referralCode || '',
      referralType: props.referralType || 'customer',
    };
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onRemoveReferral = this.onRemoveReferral.bind(this);
  }

  onClick() {
    const referralCode = this.state.value;
    const referralType = this.state.referralType;
    if (referralCode && referralCode !== '') {
      this.props.onClick(referralCode, referralType);
    }
  }

  onRemoveReferral() {
    const referralCode = this.state.value;
    this.props.onRemoveReferral(this.props.referralCode);
    this.setState({ value: '' });
  }

  onChange(value: string) {
    this.setState({ value });
  }

  changeReferralType(value: string) {
    this.setState({ referralType: value });
  }

  renderReferranceCodeBox() {
    const referralCode = this.state.value;
    return (
      <div className="ApplyPromoCode ">
        <TextInput
          placeholder="Enter Referral Code"
          className={'form-control'}
          onChange={(val: string) => this.onChange(val)}
          default={this.state.value || ''}
          disabled={this.props.referralCode && this.props.referralCode}
        ></TextInput>
        {this.props.referralCode && this.referralRemoveButton()}
        {!this.props.referralCode && this.referralApplyButton()}
      </div>
    );
  }

  referralApplyButton() {
    return (
      <div className="ApplyCoupon__btn">
        <button
          type="button"
          className="btn btn-primary py-0 px-4 h-[88%] absolute ltr:right-[3px] rtl:left-[3px] top-[7%] text-sm"
          onClick={() => this.onClick()}
        >
          {this.props.referralApplyText || 'Apply'}
        </button>
      </div>
    );
  }

  referralRemoveButton() {
    return (
      <div className="ApplyCoupon__btn">
        <button
          type="button"
          className="btn btn-primary py-0 px-4 h-[88%] absolute ltr:right-[3px] rtl:left-[3px] top-[7%] text-sm"
          onClick={() => this.onRemoveReferral()}
        >
          Remove
        </button>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="grid grid-cols-2 gap-x-1 ">
          <div className="col-span-2 md:col-span-1 md:pt-0">
            <SelectInput
              className="form-control-select"
              label=""
              placeholder="Select"
              data={[
                { _id: 'customer', name: 'Referral code' },
                // { _id: 'staff', name: 'Employee code' },
              ]}
              optionLabel="name"
              optionValue="_id"
              default={this.props.referralType || 'customer'}
              disabled={this.props.referralCode && this.props.referralCode}
              onChange={(value: string) => this.changeReferralType(value)}
            />
          </div>
          <div className="col-span-2 md:col-span-1 md:pt-0">
            {this.renderReferranceCodeBox()}
          </div>
        </div>
      </>
    );
  }
}

export default PromoCode;
