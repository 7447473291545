import React from 'react';
import { LogoutButtonProps } from './props';
import Button from '../../button';
import { useDispatch } from 'react-redux';

import { actions as userActions } from '../../../store/reducers/authUser';
import { actions as commonActions } from '../../../store/reducers/common';
import { useHistory } from 'react-router';
import { RouteKeys } from '../../../containers/route-keys';

const LogoutButton: React.SFC<LogoutButtonProps> = (
  props: LogoutButtonProps
) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const logout = () => {
    dispatch(userActions.logout());
    dispatch(commonActions.clearData());
    history.replace(RouteKeys.Login);
  };

  return (
    <span className="cursor-pointer" onClick={logout}>
      <svg width="20" height="22" viewBox="0 0 20 22" fill="none">
        <path
          d="M16.3601 5.64062C17.6185 6.89941 18.4754 8.50307 18.8224 10.2488C19.1694 11.9946 18.991 13.804 18.3098 15.4484C17.6285 17.0927 16.4749 18.4982 14.9949 19.487C13.515 20.4758 11.775 21.0035 9.99512 21.0035C8.21521 21.0035 6.47527 20.4758 4.99529 19.487C3.51532 18.4982 2.36176 17.0927 1.68049 15.4484C0.999212 13.804 0.82081 11.9946 1.16784 10.2488C1.51487 8.50307 2.37174 6.89941 3.63012 5.64062"
          stroke="#151817"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 1V11"
          stroke="#151817"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
    // <Button className={props.className} type="button" btnlink onClick={logout}>
    //   <i className="icon-logout"></i>
    //   {' Logout'}
    // </Button>
  );
};

export default LogoutButton;
