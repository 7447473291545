import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import './CourseTab.scss';
import Button from '../../button';
import { formatMultiLanguageText } from '../../../utils/multi-language-utils';
import { StudentCourse } from '../../../models/user';
import CoursePlan from '../../../components/on-boarding/course-plan';

interface CustomProps {
  courses: StudentCourse[];
  selectedId?: string;
  onSelect: (courseType: any,course: any) => void;
}

interface CustomState {
  isMore: boolean;
  activeIndex?: number;
  isExpanded: boolean;
  disableSelect: boolean;
  selectedDetails?: StudentCourse;

}

class CourseTab extends Component<CustomProps, CustomState> {
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      isMore: false,
      isExpanded: false,
      disableSelect: false,
    };
  }

  onChangeExpanded() {
    this.setState((prevState) => {
      return {
        isExpanded: !prevState.isExpanded,
      };
    });
  }

  render() {

    const { courses } = this.props;

    let selectedIndex = 1;
    if(this.props.selectedId){
      const selectedDetails = this.props.courses?.filter((x) => x.courseTypeId === this.props.selectedId?.toString());
      if(selectedDetails[0]?.courseType?.code === 'R' ){
        selectedIndex = 1;
      }else if(selectedDetails[0]?.courseType?.code === 'F' || selectedDetails[0]?.courseType?.code === 'PREF'  ){
        selectedIndex = 2;
      }else{
        if(selectedDetails[0]?.course?.isLumpSumCourse && !selectedDetails[0]?.course?.isVipCourse){
          selectedIndex = 3;
        }else if(selectedDetails[0]?.course?.isLumpSumCourse && selectedDetails[0]?.course?.isVipCourse){
          selectedIndex = 4;
        }
      }
    }

    const activeTab = this.state.activeIndex ?? selectedIndex;
    const regularCourses = courses?.filter((x) => x.courseType?.code === 'R');
    const flexiCourses = courses?.filter((x) => x.courseType?.code === 'F' || x.courseType?.code === 'PREF');
    const lumpsumCourses = courses?.filter((x) => x.course?.isLumpSumCourse && !x.course?.isVipCourse);
    const vipCourses = courses?.filter((x) => x.course?.isLumpSumCourse && x.course?.isVipCourse);

    return (
      <div className="mb-[15px]">

      <>
       <div className="tabs mb-5">
           { courses && regularCourses?.length > 0 && (
              <button className={`course-type_span border-0 text-[10px] md:text-base font-medium mr-3 rounded-3xl lg:px-5 px-3 py-2 ${activeTab === 1 ? 'active bg-primary text-white' : 'text-[#6F7171]'}`}
                onClick={() => this.setState({ activeIndex: 1 }) }
              >
                Regular
              </button>
              )
            }
            { courses && flexiCourses?.length > 0 && (
            <button
              className={`course-type_span border-0 text-[10px] md:text-base font-medium mr-3 rounded-3xl lg:px-5 px-3 py-2 ${activeTab === 2 ? 'active bg-primary text-white' : 'text-[#6F7171]'}`}
              onClick={() => this.setState({ activeIndex: 2 }) }
            >
              Flexi
            </button>
              )
            }
            { courses && lumpsumCourses?.length > 0 && (
            <button
              className={`course-type_span border-0 text-[10px] md:text-base font-medium mr-3 rounded-3xl lg:px-5 px-3 py-2 ${activeTab === 3 ? 'active bg-primary text-white' : 'text-[#6F7171]'}`}
              onClick={() => this.setState({ activeIndex: 3 }) }
            >
              Lumpsum
            </button>
              )
            }
            { courses && vipCourses?.length > 0 && (
            <button
              className={`course-type_span border-0 text-[10px] md:text-base font-medium mr-3 rounded-3xl lg:px-5 px-3 py-2 ${activeTab === 4 ? 'active bg-primary text-white' : 'text-[#6F7171]'}`}
              onClick={() => this.setState({ activeIndex: 4 }) }
            >
              VIP
            </button>
              )
            }
         </div>
        <div className="panels">
          { courses && regularCourses?.length > 0 && (
          <div className={`panel px-0 ${activeTab === 1 ? 'active' : ''}`}>

              {regularCourses.map((course, index) => {
                return (
                <CoursePlan
                  key={`${index}`}
                  course={course}
                  onSelect={() => {
                    this.props.onSelect(course?.courseType?.name?.en, course);
                  }}
                  isExpanded={this.state.isExpanded}
                  onChangeExpanded={this.onChangeExpanded}
                  selectedId={this.props.selectedId}
                  disableSelect={this.state.disableSelect}
                />
                );
              })}
          </div>
          )
            }
          { courses && flexiCourses?.length > 0 && (
          <div className={`panel px-0 ${activeTab === 2 ? 'active' : ''}`}>
              {flexiCourses.map((course, index) => {
                return (
                <CoursePlan
                  key={`${index}`}
                  course={course}
                  onSelect={() => {
                    this.props.onSelect(course?.courseType?.name?.en, course);
                  }}
                  isExpanded={this.state.isExpanded}
                  onChangeExpanded={this.onChangeExpanded}
                  selectedId={this.props.selectedId}
                  disableSelect={this.state.disableSelect}
                />
                );
              })}
          </div>
          )
            }
            { courses && courses?.length > 0 && (
          <div className={`panel px-0 ${activeTab === 3 ? 'active' : ''}`}>
              {lumpsumCourses.map((course, index) => {
                return (
                <CoursePlan
                  key={`${index}`}
                  course={course}
                  onSelect={() => {
                    this.props.onSelect(course?.courseType?.name?.en, course);
                  }}
                  isExpanded={this.state.isExpanded}
                  onChangeExpanded={this.onChangeExpanded}
                  selectedId={this.props.selectedId}
                  disableSelect={this.state.disableSelect}
                />
                );
              })}
          </div>
          )
            }
          { courses && courses?.length > 0 && (
          <div className={`panel px-0 ${activeTab === 4 ? 'active' : ''}`}>
                {vipCourses.map((course, index) => {
                return (
                <CoursePlan
                  key={`${index}`}
                  course={course}
                  onSelect={() => {
                    this.props.onSelect(course?.courseType?.name?.en, course);
                  }}
                  isExpanded={this.state.isExpanded}
                  onChangeExpanded={this.onChangeExpanded}
                  selectedId={this.props.selectedId}
                  disableSelect={this.state.disableSelect}
                />
                );
              })}
          </div>
          )
            }
        </div>
      </>
        {/* {this.renderPlanHeader()}
        <div className="sm:ltr:pl-[30px] sm:rtl:pr-[30px] md:-mt-3">
          {this.renderInfoList()}
          {this.renderReadMoreLessButton()}
        </div> */}
      </div>
    );
  }
}

export default CourseTab;
