// core
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
// action creators
import { actions as authUserActions } from '../../store/reducers/authUser';

// components
import { OTPInput } from '../../components/form-group';

// helpers
import { validateFields, serializeFields, validators } from '../helpers';
import Button from '../../components/button';

import './StepForm.scss';
import { Dict } from '../../models/interfaces';
import { State } from '../../store/interfaces';
import { useGTagConversionTracker } from '../../hooks/gtag';
import { GtagSendTo } from '../../utils/gtag';
import loaderImg from '../../assets/images/loader-white.svg';

const StepForm: React.SFC = () => {
  useGTagConversionTracker({
    allow_custom_scripts: true,
    send_to: GtagSendTo.SignupOTP,
  });

  const { isLoading, verify } = useSelector((state: State) => state.authUser);

  const [fields, setFields] = useState<Dict>({
    otp: {
      value: '',
      label: 'OTP',
      error: '',
      validators: [validators.minLength(6, 'OTP should have 6 letters')],
    },
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = (credentials: any) =>
    dispatch(
      authUserActions.loginWithOTP(credentials, () =>
        dispatch(authUserActions.fetchProfile())
      )
    );
  const sendOTPAction = (id: string) => dispatch(authUserActions.sendOTP(id));

  const getInput = (inputField: string, value: any) => {
    setFields({
      ...fields,
      [inputField]: {
        ...fields[inputField],
        value,
      },
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    const validateOptions = {
      fieldsToValidate: fields,
    };
    const { areFieldsValid, updatedFields } = validateFields(validateOptions);
    // show error messages (if any)
    setFields({
      ...fields,
      ...updatedFields,
    });

    if (areFieldsValid) {
      const readyFields = serializeFields(fields);
      submitForm(readyFields);
    }
  };

  const submitForm = async (readyFields: Dict) => {
    try {
      await onSubmit({
        id: (verify && verify.id) || '',
        otp: readyFields.otp,
      });
      history.replace('/signup/onboard');
    } catch (error) {
      // console.log('Login error:', error);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setFields({
          ...fields,
          otp: {
            ...fields.otp,
            error: error.response.data.message,
          },
        });
      }
    }
  };

  const sendOTP = async () => {
    if (!verify) {
      toastr.error('Error', 'Unable to send! Please Try again!');
      return;
    }
    try {
      // console.log('sending otp to : ', this.props.verify.id);
      await sendOTPAction((verify && verify.id) || '');
      toastr.success('Resent OTP!', 'Please enter the new OTP to verify!');
    } catch (error) {
      toastr.error('Error Sending OTP!', 'Please rensed again!');
      // console.log('rend otp failed: ', error);
    }
  };

  return (
    <div
      className=" bg-white shadow-[0px_3px_5px_rgba(0,0,0,0.1)] rounded-md p-4 md:px-14 md:py-12 mt-4 md:mt-9 max-w-[530px] mx-auto"
      style={{ boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)' }}
    >
      <form onSubmit={submitHandler}>
        <div className="mb-8">
          <h4 className="mb-2 font-semibold">Verify your mobile number</h4>
          {verify && (
            <p className="text-muted text-sm">
              We sent a six digit code to{' '}
              <span className="Verification__number">{verify.phoneNumber}</span>
            </p>
          )}
          <p className="text-muted text-sm">
            Enter the code below to confirm your mobile number.
          </p>
        </div>

        <OTPInput
          onChange={(value) => getInput('otp', value)}
          error={fields.otp.error}
        />

        <div className="text-muted mb-4 py-2 flex justify-content-lg-start justify-content-center text-sm mt-5">
          <span className="mr-1 text-muted">Did not receive OTP?</span>
          <span
            onClick={() => sendOTP()}
            className="text-sm underline ml-1 text-primary cursor-pointer"
          >
            Resend OTP
          </span>
        </div>
        <div className="row justify-content-lg-start justify-content-center">
          <div className="col-lg-12 col-md-6 col-sm-8 col-12">
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary w-full normal-case inline-flex justify-center"
            >
              {isLoading && <img src={loaderImg} width={20} className="mr-2" />}
              Verify & Proceed
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StepForm;
