import React, { Component } from 'react';

import StepForm from './StepForm';

import Header from '../header';
import Footer from '../footer';

class Step extends Component {
  componentDidMount(): void {
    document.body.scrollIntoView({ behavior: 'smooth' });
  }
  render() {
    return (
      <>
        <Header />
        <StepForm />
        <Footer />
      </>
    );
  }
}

export default Step;
