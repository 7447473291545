import React, { Component } from 'react';

import HeaderCanvas from './HeaderCanvas';
import UserHeaderCanvas from './UserHeaderCanvas';

interface CustomProps {
  headerType?: 'logged-in' | 'pre-login';
}
class Header extends Component<CustomProps> {
  render() {
    return this.props.headerType !== 'logged-in' ? (
      <HeaderCanvas />
    ) : (
      <UserHeaderCanvas />
    );
  }
}

export default Header;
