import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import StepForm from './StepForm';
import StepVisual from './StepVisual';
import StepHeader from './StepHeader';

import './Step.scss';
import { Link } from 'react-router-dom';
import Header from '../header';
import { RouteKeys } from '../route-keys';
import Footer from '../footer';

class Step extends Component {
  componentDidMount(): void {
    document.body.scrollIntoView({ behavior: 'smooth' });
  }
  render() {
    return (
      <>
        <Header />
        <div className="bg-muted/5">
          <div className="pt-[110px] md:pt-[200px] pb-[85px]">
            <div className="container">
              <div className="text-center">
                <h1 className="text-2xl md:text-[32px] font-bold">Register</h1>
                <p className="text-muted text-sm md:text-base pt-2">
                  Already have an account?
                  <Link to={RouteKeys.Login}>
                    <a className="underline text-primary font-thin ml-1">
                      Login
                    </a>
                  </Link>
                </p>
              </div>
              <StepForm />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  } // end of render
}

// Step.propTypes = {};

// Step.defaultProps = {};

export default Step;
