import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { AccordionBlockCustomeProps } from './props';
import {
  CourseTypeIcon,
  PaymentMethodIcon,
  PaymentPlanIcon,
  PersonalInfoIcon,
} from '../icons';

const AccordionBlock = (props: AccordionBlockCustomeProps) => {
  const ref = useRef(null);

  const {
    children,
    isCurrent,
    isTouched,
    title,
    icon,
    actionText,
    summaries,
    gotoNextBlock,
    addSummary,
    overrideTick,
    showTick,
    suppressParentProps,
    gotoPreviousBlock,
    showAll,
  } = props;

  const parentProps = {
    gotoNextBlock,
    gotoPreviousBlock,
    addSummary,
  };

  function handleClick() {
    if (props.openBlock) props.openBlock(ref);
  }

  function renderActionText() {
    const text = actionText || '';
    const summaryList = summaries || {};
    if (!isCurrent && Object.keys(summaryList).length) {
      return (
        <div className="summary">
          {Object.keys(summaryList).map((summaryKey) => {
            return (
              <>
                {summaryList[summaryKey].label}
                <span className="summary__item-type">
                  {summaryList[summaryKey].value}
                </span>
              </>
            );
          })}
        </div>
      );
    }
    return <span>{text}</span>;
  }

  const borderClasses = `${
    props.noBorder ? '' : 'AccordionBlocks__item--border'
  }`;

  const tickRender = overrideTick
    ? showTick
      ? 'visible'
      : 'invisible'
    : isCurrent
    ? 'invisible'
    : isTouched
    ? 'visible'
    : 'invisible';

  const touchedClass = isTouched ? 'active' : '';
  const classes = props.className ? props.className : '';

  const renderIcon = (type: string) => {
    switch (type) {
      case 'PersonalDetailIcon':
        return <PersonalInfoIcon />;
      case 'CourseTypeIcon':
        return <CourseTypeIcon />;
      case 'PaymentPlanIcon':
        return <PaymentPlanIcon />;
      case 'PaymentMethodIcon':
        return <PaymentMethodIcon />;
    }
  };

  return (
    <div
      ref={ref}
      key={title}
      className={`shadow bg-white p-[15px] py-5 md:px-8 md:py-7 rounded-md mt-4 sm:mt-9 mb-5 relative ${borderClasses} ${touchedClass} ${classes}`}
    >
      <div
        className={`flex ${
          props.headSize ? 'AccordionBlocks__heading--' + props.headSize : ''
        }`}
        role="presentation"
        onClick={handleClick}
      >
        {/* <div className="AccordionBlocks__heading-left"> */}
        {icon && (
          <div className="w-10">
            {/* <img src={icon} alt={title} width="" /> */}
            {renderIcon(icon)}
          </div>
        )}
        <div>
          <h4 className="text-lg font-semibold">{title}</h4>
          {(actionText || Object.keys(summaries || {}).length > 0) && (
            <p className="text-sm text-muted">{renderActionText()}</p>
          )}
        </div>
        {/* </div> */}
        <span
          className={`${tickRender} absolute ltr:left-0 rtl:right-0 top-0 w-8 h-8 overflow-hidden ltr:rounded-tl rtl:rounded-tr before:w-11 before:h-11 before:bg-primary before:rotate-45 before:inline-block ltr:before:left-[-22px] rtl:before:right-[-22px] before:top-[-22px] before:absolute isolate`}
        >
          {/* <i className="icon-tick" /> */}
          <svg
            width="10"
            height="8"
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="relative top-1.5 ltr:left-1.5 rtl:right-1.5"
          >
            <path
              d="M9 1L3.5 6.5L1 4"
              stroke="white"
              stroke-width="1.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>

      <div
        className={`AccordionBlocks__collapse ${
          isCurrent || showAll ? 'AccordionBlocks__collapse--in' : ''
        }`}
      >
        <div
          className={` ${
            props.bodySize ? 'AccordionBlocks__body--' + props.bodySize : ''
          }`}
        >
          {suppressParentProps
            ? children
            : React.cloneElement(children, parentProps)}
        </div>
      </div>
    </div>
  );
};

AccordionBlock.propTypes = {
  showAll: PropTypes.bool,
  isCurrent: PropTypes.bool,
  isTouched: PropTypes.bool,

  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  actionText: PropTypes.string.isRequired,

  children: PropTypes.element.isRequired,
  summaries: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  // events
  openBlock: PropTypes.func,
  gotoNextBlock: PropTypes.func,
  gotoPreviousBlock: PropTypes.func,
  addSummary: PropTypes.func,
};

// many of the props are coming from parent and is required for proper functioning
// but since the child will get rendered before parent, we are giving a default value for them.
AccordionBlock.defaultProps = {
  isCurrent: false,
  isTouched: false,
  icon: undefined,

  summaries: {},
  openBlock: () => {
    return;
  },
  gotoNextBlock: () => {
    return;
  },
  gotoPreviousBlock: () => {
    return;
  },
  addSummary: () => {
    return;
  },
};

export default AccordionBlock;
