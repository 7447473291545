import React from 'react';

export const PaymentMethodIcon = (props: any) => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none">
      <path
        d="M20.2701 17.3177H15.9874C14.2315 17.3177 12.7754 15.8618 12.7754 14.1061C12.7754 12.3076 14.2315 10.8945 15.9874 10.8945H20.2701C20.4843 10.8945 20.6984 11.0658 20.6984 11.2799V16.8895C20.6984 17.1465 20.5271 17.3177 20.2701 17.3177ZM15.9874 11.7081C14.6598 11.7081 13.5891 12.7787 13.5891 14.1061C13.5891 15.3908 14.7026 16.5041 15.9874 16.5041H19.8847V11.7081H15.9874Z"
        fill="#151817"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9875 11.1643C14.3781 11.1643 13.0451 12.459 13.0451 14.1062C13.0451 15.713 14.3805 17.0482 15.9875 17.0482H20.2702C20.3374 17.0482 20.3725 17.027 20.3901 17.0094C20.4076 16.9918 20.4289 16.9568 20.4289 16.8896V11.28C20.4289 11.2667 20.4221 11.2403 20.3881 11.2108C20.3533 11.1807 20.3079 11.1643 20.2702 11.1643H15.9875ZM12.5059 14.1062C12.5059 12.1565 14.0851 10.625 15.9875 10.625H20.2702C20.4466 10.625 20.6154 10.6942 20.7412 10.8033C20.8678 10.913 20.9681 11.0793 20.9681 11.28V16.8896C20.9681 17.0794 20.9037 17.2584 20.7714 17.3908C20.639 17.5231 20.46 17.5875 20.2702 17.5875H15.9875C14.0827 17.5875 12.5059 16.0109 12.5059 14.1062ZM13.3196 14.1062C13.3196 12.6298 14.511 11.4386 15.9875 11.4386H20.1544V16.7739H15.9875C14.5538 16.7739 13.3196 15.5398 13.3196 14.1062ZM15.9875 11.9779C14.8088 11.9779 13.8588 12.9277 13.8588 14.1062C13.8588 15.2419 14.8516 16.2346 15.9875 16.2346H19.6151V11.9779H15.9875Z"
        fill="#151817"
      />
      <path
        d="M17.5288 21.2999H2.45371C1.25456 21.2999 0.269531 20.315 0.269531 19.116V5.54164C0.269531 5.32754 0.440839 5.15625 0.697802 5.15625C0.954764 5.15625 1.12607 5.32754 1.12607 5.54164C1.12607 6.31243 1.76848 6.91193 2.49654 6.91193H19.3704C19.5845 6.91193 19.7987 7.08321 19.7987 7.29732V11.2797C19.7987 11.4938 19.6274 11.6651 19.3704 11.6651C19.1134 11.6651 18.9421 11.4938 18.9421 11.2797V7.72553H2.49654C1.98262 7.72553 1.46869 7.55425 1.12607 7.2545V19.116C1.12607 19.8868 1.76848 20.4863 2.49654 20.4863H17.5717C18.3425 20.4863 18.9421 19.8868 18.9421 19.116V16.9321C18.9421 16.718 19.1134 16.5467 19.3704 16.5467C19.6274 16.5467 19.7987 16.718 19.7987 16.9321V19.116C19.7558 20.315 18.7708 21.2999 17.5288 21.2999Z"
        fill="#151817"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.570863 5.46635C0.551172 5.48463 0.539262 5.50925 0.539262 5.54174V19.1161C0.539262 20.1662 1.40353 21.0304 2.45381 21.0304H17.5289C18.6219 21.0304 19.489 20.1652 19.5291 19.1112V16.9322C19.5291 16.8997 19.5172 16.8751 19.4975 16.8568C19.4775 16.8382 19.4381 16.8165 19.3705 16.8165C19.3028 16.8165 19.2635 16.8382 19.2435 16.8568C19.2238 16.8751 19.2118 16.8997 19.2118 16.9322V19.1161C19.2118 20.0359 18.4915 20.756 17.5718 20.756H2.49664C1.6252 20.756 0.856541 20.0413 0.856541 19.1161V6.66045L1.30371 7.05167C1.58862 7.30093 2.03568 7.456 2.49664 7.456H19.2118V11.2798C19.2118 11.3123 19.2238 11.3369 19.2435 11.3552C19.2635 11.3738 19.3028 11.3956 19.3705 11.3956C19.4381 11.3956 19.4775 11.3738 19.4975 11.3552C19.5172 11.3369 19.5291 11.3123 19.5291 11.2798V7.29742C19.5291 7.28408 19.5224 7.25769 19.4883 7.2282C19.4536 7.19806 19.4082 7.18166 19.3705 7.18166H2.49664C1.6252 7.18166 0.856541 6.46691 0.856541 5.54174C0.856541 5.50925 0.844631 5.48463 0.824939 5.46635C0.804906 5.44775 0.765539 5.42598 0.697901 5.42598C0.630264 5.42598 0.590897 5.44775 0.570863 5.46635ZM0 5.54174C0 5.16022 0.311226 4.88672 0.697901 4.88672C1.08458 4.88672 1.3958 5.16022 1.3958 5.54174C1.3958 6.15814 1.91196 6.6424 2.49664 6.6424H19.3705C19.5469 6.6424 19.7157 6.71164 19.8415 6.82067C19.9681 6.93035 20.0684 7.09666 20.0684 7.29742V11.2798C20.0684 11.6613 19.7572 11.9348 19.3705 11.9348C18.9838 11.9348 18.6726 11.6613 18.6726 11.2798V7.99527H2.49664C2.1164 7.99527 1.7299 7.91091 1.3958 7.74966V19.1161C1.3958 19.7325 1.91196 20.2168 2.49664 20.2168H17.5718C18.1938 20.2168 18.6726 19.738 18.6726 19.1161V16.9322C18.6726 16.5507 18.9838 16.2772 19.3705 16.2772C19.7572 16.2772 20.0684 16.5507 20.0684 16.9322V19.1209L20.0682 19.1258C20.0203 20.4676 18.9183 21.5697 17.5289 21.5697H2.45381C1.10578 21.5697 0 20.4641 0 19.1161V5.54174Z"
        fill="#151817"
      />
      <path
        d="M17.0577 7.72731C16.8436 7.72731 16.6295 7.55602 16.6295 7.34191V4.17313H15.0877C14.8736 4.17313 14.6594 4.00184 14.6594 3.78774C14.6594 3.57363 14.8307 3.40234 15.0877 3.40234H17.0149C17.229 3.40234 17.4432 3.57363 17.4432 3.78774V7.34191C17.4432 7.55602 17.2719 7.72731 17.0577 7.72731ZM0.697802 5.97163C0.483666 5.97163 0.269531 5.80034 0.269531 5.58624C0.269531 4.38724 1.25456 3.40234 2.45371 3.40234H4.16679C4.38093 3.40234 4.59506 3.57363 4.59506 3.78774C4.59506 4.00184 4.42376 4.17313 4.16679 4.17313H2.45371C1.68283 4.17313 1.08326 4.81545 1.08326 5.54341C1.08326 5.80034 0.911937 5.97163 0.697802 5.97163Z"
        fill="#151817"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.45381 3.67207C1.40353 3.67207 0.539262 4.53628 0.539262 5.58634C0.539262 5.59968 0.546019 5.62607 0.580042 5.65555C0.614829 5.6857 0.660193 5.7021 0.697901 5.7021C0.730431 5.7021 0.755073 5.69017 0.77336 5.67048C0.791961 5.65045 0.813724 5.61111 0.813724 5.54351C0.813724 4.6721 1.52861 3.9036 2.45381 3.9036H4.16689C4.23453 3.9036 4.2739 3.88183 4.29393 3.86323C4.31362 3.84494 4.32553 3.82033 4.32553 3.78784C4.32553 3.77449 4.31878 3.7481 4.28475 3.71862C4.24997 3.68847 4.2046 3.67207 4.16689 3.67207H2.45381ZM0 5.58634C0 4.23839 1.10578 3.13281 2.45381 3.13281H4.16689C4.34332 3.13281 4.51209 3.20206 4.63791 3.31108C4.76448 3.42077 4.8648 3.58707 4.8648 3.78784C4.8648 4.16936 4.55357 4.44286 4.16689 4.44286H2.45381C1.83725 4.44286 1.35299 4.959 1.35299 5.54351C1.35299 5.93023 1.07938 6.24136 0.697901 6.24136C0.521475 6.24136 0.352704 6.17212 0.226889 6.06309C0.100311 5.9534 0 5.7871 0 5.58634ZM14.9607 3.71245C14.9411 3.73073 14.9291 3.75535 14.9291 3.78784C14.9291 3.80118 14.9359 3.82757 14.9699 3.85705C15.0047 3.8872 15.0501 3.9036 15.0878 3.9036H16.8992V7.34201C16.8992 7.35536 16.9059 7.38175 16.94 7.41123C16.9748 7.44138 17.0201 7.45778 17.0578 7.45778C17.1231 7.45778 17.1736 7.40718 17.1736 7.34201V3.78784C17.1736 3.77449 17.1669 3.7481 17.1329 3.71862C17.0981 3.68847 17.0527 3.67207 17.015 3.67207H15.0878C15.0201 3.67207 14.9808 3.69385 14.9607 3.71245ZM14.3899 3.78784C14.3899 3.40632 14.7011 3.13281 15.0878 3.13281H17.015C17.1914 3.13281 17.3602 3.20206 17.486 3.31108C17.6126 3.42077 17.7129 3.58707 17.7129 3.78784V7.34201C17.7129 7.70507 17.4208 7.99704 17.0578 7.99704C16.8814 7.99704 16.7126 7.92779 16.5868 7.81877C16.4602 7.70908 16.3599 7.54278 16.3599 7.34201V4.44286H15.0878C14.9114 4.44286 14.7426 4.37362 14.6168 4.26459C14.4902 4.1549 14.3899 3.9886 14.3899 3.78784Z"
        fill="#151817"
      />
      <path
        d="M15.1308 6.31328C15.0451 6.31328 14.9166 6.27045 14.8738 6.22763L9.43479 1.21753L4.85229 6.18481C4.68098 6.3561 4.42403 6.3561 4.29554 6.22763C4.12424 6.05635 4.12423 5.79942 4.25271 5.67096L9.09217 0.403923C9.26348 0.232638 9.52045 0.232638 9.64893 0.361102L15.3877 5.62813C15.5591 5.79942 15.5591 6.05635 15.4306 6.18481C15.3449 6.27046 15.2164 6.31328 15.1308 6.31328Z"
        fill="#151817"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.28543 0.591753L4.44677 5.85793L4.44273 5.86197C4.44208 5.86261 4.42823 5.87945 4.43033 5.91941C4.43228 5.95631 4.44822 5.99998 4.48556 6.03731C4.48621 6.03796 4.50306 6.05182 4.54306 6.04972C4.57897 6.04782 4.62127 6.03269 4.65791 5.99753L9.41867 0.837016L15.0476 6.02205C15.0529 6.02447 15.0607 6.02768 15.0709 6.03106C15.084 6.03545 15.0977 6.039 15.11 6.04133C15.116 6.04246 15.121 6.04317 15.1248 6.04358C15.1282 6.04394 15.13 6.04398 15.1302 6.04399C15.1306 6.04397 15.1359 6.04378 15.1455 6.04174C15.1554 6.03966 15.1673 6.03621 15.1799 6.03115C15.2066 6.0205 15.2271 6.00664 15.2393 5.99449C15.2399 5.99384 15.2538 5.97701 15.2517 5.93705C15.2498 5.90122 15.2347 5.85903 15.1997 5.82244L9.46173 0.556194L9.45765 0.552116C9.457 0.55147 9.44014 0.537604 9.40014 0.539709C9.36427 0.541597 9.32203 0.556696 9.28543 0.591753ZM9.8342 0.166096C9.58143 -0.0808836 9.1565 -0.0419811 8.90089 0.213596L8.89685 0.217634L4.05679 5.48532C3.80971 5.73809 3.84863 6.16305 4.10427 6.41865C4.35644 6.67079 4.78509 6.63301 5.0423 6.37583L5.04615 6.37198L9.44965 1.59873L14.6879 6.42387C14.7551 6.48826 14.8449 6.52419 14.9004 6.54266C14.9663 6.56463 15.0496 6.58325 15.1301 6.58325C15.2825 6.58325 15.4811 6.51534 15.6206 6.37583C15.8728 6.12366 15.835 5.695 15.5778 5.43781L15.5737 5.43373L9.8342 0.166096Z"
        fill="#151817"
      />
    </svg>
  );
};
