import { CourseStage } from './course';
import { BranchDetails } from './branch';
import User from './user';
import { FeeCode } from './fee-code';
import { Lesson, LectureHall, SubTopic, Topic } from './theory-lecture';

export enum ScheduleStatus {
  COMPLETED = 'COMPLETED',
  SCHEDULED = 'SCHEDULED',
  CANCELLED = 'CANCELLED',
  MARKED_FOR_RESCHEDULE = 'MARKED_FOR_RESCHEDULE',
  RESCHEDULED = 'RESCHEDULED',
}

export enum BookingStatus {
  AVAILABILE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT AVAILABLE',
  BOOKED = 'BOOKED',
}

export const CancelledScheduleStatuses = [
  ScheduleStatus.CANCELLED,
  ScheduleStatus.MARKED_FOR_RESCHEDULE,
  ScheduleStatus.RESCHEDULED,
];

export type ScheduleResource =
  | 'MockTestDevice'
  | 'SimulatorDevice'
  | 'Instructor'
  | 'LectureHall';

export enum ScheduleResourceEnum {
  MockTestDevice = 'MockTestDevice',
  SimulatorDevice = 'SimulatorDevice',
  Instructor = 'Instructor',
  LectureHall = 'LectureHall',
}

export interface Time {
  hour: number;
  minute: number;
}

export interface TimeSlot {
  _id: string;
  startTime: Time;
  endTime: Time;
  durationInMinutes: number;
}

export interface StudentSchedule {
  _id: string;
  instanceId: string;
  instance: CourseStage;
  feeCode: FeeCode;
  date: Date;
  start: Date;
  end: Date;
  status: ScheduleStatus;
  instructorId?: string;
  instructor?: User;
  branch: BranchDetails;
  arrivalTime: Time;
  startTime: Time;
  endTime: Time;
  slotIds: string[];
  slots: TimeSlot[];
  quantity: number;
  rating: Rating;
  comments: CommentDetails[];
  studentId?: string;
  student?: User;
  lectureHall?: LectureHall;
  lectureHallId?: string;
  lesson?: Lesson;
  lessonId?: string;
  language?: string;
  scheduleResource?: string;
  createdAt?: Date;
  cancellationReason?: string;
  reAttendSchedule?: boolean;
  scheduleAttendanceInfoFromCTS?: ScheduleAttendanceInfoFromCTS;
  topic?: Topic;
  subTopic?: SubTopic;
}

export interface ScheduleOption {
  startSlot: TimeSlot;
  endSlot: TimeSlot;
  slots: TimeSlot[];
  slotIds: string[];
  topRate: number;
  instructorId: string;
  instructors: Array<{ instructor: User; instructorId: string; dates: Date[] }>;
  startDate: Date;
  endDate: Date;
  platform?: string;
}

export interface TheoryLectureScheduleOption {
  lectureHallId: string;
  lectureHall: LectureHall;
  lessonId: string;
  lesson: Lesson;
  dateSlots: TheoryLectureScheduleDateSlot[];
  topRate: number;
}

export interface MockTestSimulatorScheduleOption {
  date: Date;
  slotId: string;
  slot: TimeSlot;
}

interface TheoryLectureScheduleDataMetrics {
  slotHourVariance: number;
  dateVariance: number;
}

export interface TheoryLectureScheduleDateSlot {
  start: Date;
  date: Date;
  slot: TimeSlot;
  slotId: string;
  language: string;
  quantity: number;
  capacity: number;
  status: BookingStatus;
  instructorId: string;
  instructor: User;
  metrics: TheoryLectureScheduleDataMetrics;
}

export interface Rating {
  overall: number;
  waitTime: number;
  besideManner: number;
}

export interface CommentDetails {
  _id: string;
  comment: string;
  commentedBy: CommentedBy;
}

export interface CommentedBy {
  id: string;
  userType: string;
}

export interface SlotIdAndStartEnd {
  eachDateStudentBookingIds: string[];
  eachDateStudentBookingSlot: Array<{ start: Date; end: Date }>;
}
export interface EachDateVipBookingDetails {
  [key: string]: SlotIdAndStartEnd;
}

export interface VipSlotsDetails extends TimeSlot {
  end: Date;
  start: Date;
  status: string;
  vipSlotId: string;
  noOfStudentsPerClass: number;
  quantity: number;
}
export interface VipTimeSlots {
  [key: string]: VipSlotsDetails[];
}

export interface RegulateVipSlots {
  date: Date;
  disabled: boolean;
  end: Date;
  endTime: Time;
  id: string;
  permanent: boolean;
  selected: boolean;
  slotId: string;
  start: Date;
  startTime: Time;
}

export interface VipSlotIdsEachDate {
  date: Date;
  slotId: string[];
}

export interface SelectedVipSlotDetails {
  vipSlotId: string;
  slotId: string;
  date: Date;
  startTime: Time;
  endTime: Time;
}

export interface SlotTime {
  start: Date;
  end: Date;
}

export interface SlotSelected extends SlotTime {
  selected: boolean;
}

export interface BookingConfirmationSlotDetails {
  [key: string]: Array<{
    date: any;
    slotTime: string;
  }>;
}

export interface ScheduleAttendanceInfoFromCTS {
  checkOutDateTime: Date;
  checkInDateTime: Date;
  scheduleRefNumber: any;
}
