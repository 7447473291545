import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import './Popup.scss';

interface CustomProps {
  target: string;
  left: number;
  top: number;
  cuePos: 'pos1' | 'pos2' | 'pos3';
  isList: boolean;
  children: any;
  align: string;
  right: string;
}

class Popup extends Component<CustomProps> {
  static defaultProps: Partial<CustomProps> = {
    // sidePos: 'bottom',
    cuePos: 'pos2',
    isList: false,
    top: -10,
    left: 0,
    align: 'left',
    right: '0',
  };

  private popupElement: any;
  private cueElement: any;
  private targetElement: any;
  constructor(props: CustomProps) {
    super(props);
    this.state = {};
    // need access to these DOM elements
    this.popupElement = null;
    this.cueElement = null;
    this.targetElement = null;

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.targetElement = window.document.getElementById(this.props.target);
    // toggle on/off on click
    this.targetElement.addEventListener('click', this.toggle);
    // console.log('popup: ', this.popupElement);
    // console.log('cue: ', this.cueElement);
    // console.log('target: ', this.targetElement);
    this.calculatePositions();

    // toggle off the popup on render
    this.popupElement.style.display = 'none';
  }

  componentWillUnmount() {
    this.targetElement.removeEventListener('click', this.toggle);
    // console.log('clearing listener..');
  }

  toggle() {
    // console.log('toggling..');
    this.popupElement.style.display =
      this.popupElement.style.display === 'none' ? 'block' : 'none';
  }

  calculatePositions() {
    const targetDimensions = findDimensions(this.targetElement);
    const popupDimensions = findDimensions(this.popupElement);
    // console.log('top: ', this.props.top);
    // console.log('target dimension: ', targetDimensions);
    // console.log('popup dimension: ', popupDimensions);

    const leftPos =
      targetDimensions.left - popupDimensions.width - this.props.left;
    // const topPos =
    //   targetDimensions.top + targetDimensions.height + this.props.top;
    const topPos = targetDimensions.height + this.props.top;

    this.popupElement.style.left = leftPos;
    this.popupElement.style.top = `${topPos}px`;

    // console.log('topCalc: ', topCalc);

    // console.log('target dimension: ', targetDimensions);
    // console.log('popup dimension: ', popupDimensions);

    const { cuePos } = this.props;
    if (cuePos === 'pos1') {
      this.cueElement.style.left = '10px';
    }
    if (cuePos === 'pos2') {
      this.cueElement.style.left = `${popupDimensions.width / 2 - 5}px`;
    }
    if (cuePos === 'pos3') {
      this.cueElement.style.left = `${popupDimensions.width - 35}px`;
    }
  }

  render() {
    return (
      <div
        className={`Popup ${
          this.props.align ? `Popup--${this.props.align}` : ''
        }`}
        style={{ right: `${this.props.right ? `${this.props.right}px` : ''}` }}
        ref={(el) => {
          this.popupElement = el;
        }}
      >
        <span
          className="Popup__cue"
          ref={(cueElement) => {
            this.cueElement = cueElement;
          }}
        />
        {this.props.isList ? (
          <ul className="Popup__content">{this.props.children}</ul>
        ) : (
          <div className="Popup__content">{this.props.children}</div>
        )}
      </div>
    );
  }
}

// Popup.propTypes = {
//   target: PropTypes.string.isRequired,
//   // sidePos: PropTypes.oneOf(['left', 'top', 'right', 'bottom']),
//   cuePos: PropTypes.oneOf(['pos1', 'pos2', 'pos3']),
//   isList: PropTypes.oneOf([true]),
//   children: PropTypes.node.isRequired,
//   top: PropTypes.number,
//   left: PropTypes.number,
//   align: PropTypes.string,
//   right: PropTypes.string,
// };

// Popup.defaultProps = {
//   // sidePos: 'bottom',
//   cuePos: 'pos2',
//   isList: true,
//   top: -10,
//   left: 0,
//   align: 'left',
//   right: '0',
// };

export default Popup;

function findDimensions(obj: any): any {
  const dimensions: any = [];
  dimensions.height = obj.offsetHeight;
  dimensions.width = obj.offsetWidth;
  // offsetLeft & offsetTop are relative to the nearest parent. So this does not
  // guarantee the absolute left & top values from the root(html). To calculate
  // the right dimensions starting from the root html, we traverse back to each
  // parent & keep on adding their left & top values until we reach the root parent.
  let curleft = 0;
  let curtop = 0;
  if (obj.offsetParent) {
    do {
      curleft += obj.offsetLeft;
      curtop += obj.offsetTop;
      obj = obj.offsetParent;
    } while (obj);
  }
  dimensions.left = curleft;
  dimensions.top = curtop;
  return dimensions;
}
