import React from 'react';

export const CallIcon = (props: any) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.55883 4.08275C8.827 4.13583 9.85833 5.17125 9.90792 6.43942C9.92017 6.75383 10.1786 7 10.4901 7C10.4983 7 10.5052 7 10.5134 6.99942C10.8354 6.98717 11.0857 6.71592 11.0734 6.39392C11.0005 4.52258 9.47858 2.99542 7.60783 2.91725C7.29925 2.91492 7.014 3.1535 7.00058 3.4755C6.98717 3.7975 7.23683 4.06933 7.55883 4.08275ZM7 0C6.67742 0 6.41667 0.261333 6.41667 0.583333C6.41667 0.905333 6.67742 1.16667 7 1.16667C10.2165 1.16667 12.8333 3.7835 12.8333 7C12.8333 7.322 13.0941 7.58333 13.4167 7.58333C13.7392 7.58333 14 7.322 14 7C14 3.14008 10.8599 0 7 0ZM9.33333 8.16667C8.17308 8.16667 7.14992 8.848 6.68442 9.86242C5.58892 9.30417 4.69583 8.41167 4.13817 7.31558C5.15142 6.85008 5.83333 5.82692 5.83333 4.66667C5.83333 3.05842 4.52492 1.75 2.91667 1.75C1.30842 1.75 0 3.05842 0 4.66667C0 9.81283 4.18717 14 9.33333 14C10.9416 14 12.25 12.6916 12.25 11.0833C12.25 9.47508 10.9416 8.16667 9.33333 8.16667ZM9.33333 12.8333C4.83 12.8333 1.16667 9.17 1.16667 4.66667C1.16667 3.70183 1.95183 2.91667 2.91667 2.91667C3.8815 2.91667 4.66667 3.70183 4.66667 4.66667C4.66667 5.51133 4.06583 6.23408 3.23808 6.38517L2.548 6.51175L2.79767 7.16742C3.50583 9.02358 4.97642 10.4948 6.83317 11.2029L7.48883 11.4526L7.61483 10.7625C7.76592 9.93417 8.48925 9.33333 9.33333 9.33333C10.2982 9.33333 11.0833 10.1185 11.0833 11.0833C11.0833 12.0482 10.2982 12.8333 9.33333 12.8333Z"
        fill="white"
      />
    </svg>
  );
};
