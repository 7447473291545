import { stringify } from 'query-string';
import { UploadStudentDocumentInfo } from './../models/upload';
import * as http from './http';
import ApiService from './service';

const serviceEndpoint = 'user/v1';

export default class UserService extends ApiService {
  public get loginUrl() {
    return `${this.apiDomain}/${serviceEndpoint}/auth/login`;
  }

  public async signup(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/auth/signup`;
    return http.post(url, data);
  }

  public async checkUserLogin(credentials: {
    username: string;
    password: string;
  }) {
    const url = `${this.apiDomain}/${serviceEndpoint}/auth/login`;
    const data = {
      user: { email: credentials.username, password: credentials.password },
    };
    return http.post(url, data);
  }

  public async loginWithOTP(data: { id: string; otp: string }) {
    const url = `${this.apiDomain}/${serviceEndpoint}/auth/verify_otp`;
    return http.post(url, data);
  }

  public async sendOTP(id: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/auth/resend-otp`;
    const data = { id };
    return http.post(url, data);
  }

  public async requestForgotPasswordLink(credentials: {
    email?: string;
    phone?: string;
    role: string;
  }) {
    const url = `${this.apiDomain}/${serviceEndpoint}/auth/forgot-password`;
    const data = {
      user: {
        email: credentials.email,
        phone: credentials.phone,
        role: credentials.role,
      },
    };

    return http.post(url, data);
  }

  public async resetForgotPasswordWithOtp(credentials: {
    id: string;
    otp: string;
    password: string;
  }) {
    const url = `${this.apiDomain}/${serviceEndpoint}/auth/reset-password-otp`;
    const data = {
      id: credentials.id,
      otp: credentials.otp,
      password: credentials.password,
    };

    return http.post(url, data);
  }

  public async verifyForgotPasswordOTP(credentials: {
    id: string;
    otp: string;
  }) {
    const url = `${this.apiDomain}/${serviceEndpoint}/auth/reset-password-otp`;
    const data = {
      id: credentials.id,
      otp: credentials.otp,
    };
    return http.post(url, data);
  }

  public async verifyEmail(userId: string, verifyToken: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/auth/verify-email/${userId}`;
    const data = {
      verifyToken,
    };
    return http.put(url, data, this.token);
  }

  public async sendVerificationEmail() {
    const url = `${this.apiDomain}/${serviceEndpoint}/auth/send-verify-email`;
    const data = {};
    return http.post(url, data, this.token);
  }

  public async actionAdditionalTrainingRequest(isApproved: boolean) {
    const url = `${this.apiDomain}/${serviceEndpoint}/verify-additional-training`;
    const data = { isApproved };
    return http.post(url, data, this.token);
  }

  public async resetPassword(credentials: {
    id: string;
    password: string;
    confirmPassword: string;
  }) {
    const url = `${this.apiDomain}/${serviceEndpoint}/auth/reset-password`;
    const data = {
      id: credentials.id,
      password: credentials.password,
      confirmPassword: credentials.confirmPassword,
    };
    return http.put(url, data);
  }

  public async getStudentProfile() {
    const url = `${this.apiDomain}/${serviceEndpoint}/student`;
    return http.get(url, this.token);
  }

  public async getPaymentHistory() {
    const query = {
      _sort: 'createdAt',
      _order: 'ASC',
    };

    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/payment-history?${stringify(query)}`;
    return http.get(url, this.token);
  }

  public async getLumpsumPaymentDetails(
    courseId: string,
    invoiceNumber?: string,
    overrideToken?: string
  ) {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/lumpsum-payment-history?courseId=${courseId}${
      invoiceNumber ? `&invoiceNumber=${invoiceNumber}` : ''
    }`;

    return http.get(url, overrideToken || this.token);
  }

  public async getCorporatePaymentDetails(
    corporateId: string,
    invoiceNumber?: string,
    overrideToken?: string
  ) {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/corporate/${corporateId}/get-invoice-detail${
      invoiceNumber ? `/${invoiceNumber}` : ''
    }`;

    return http.get(url, overrideToken || this.token);
  }

  public async getSinglePaymentDetails(id: string, overrideToken?: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/payment-history/${id}`;
    return http.get(url, overrideToken || this.token);
  }

  public async downloadInvoicePdf(id: string, isAdvancePayment?: boolean) {
    let url = `${this.apiDomain}/${serviceEndpoint}/payment-history/${id}/pdf/`;
    if (isAdvancePayment) {
      url = `${this.apiDomain}/${serviceEndpoint}/payment-history/pre-registration/${id}/pdf/`;
    }
    return http.download(url, this.token, 'application/pdf');
  }

  public async downloadLumpsumInvoicePdf(
    courseId: string,
    invoiceNumber?: string
  ) {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/lumpsum-payment/pdf/?courseId=${courseId}${
      invoiceNumber ? `&invoiceNumber=${invoiceNumber}` : ''
    }`;
    return http.download(url, this.token, 'application/pdf');
  }

  public async getStudentDocuments(overrideToken?: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/documents`;
    return http.get(url, overrideToken || this.token);
  }

  public async getStudentCourse() {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/course`;
    return http.get(url, this.token);
  }

  public async skipCourseStage(data: { stageId: string }) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/course/stage/skip/`;
    return http.post(url, data, this.token);
  }

  public async uploadDocument(docInfo: UploadStudentDocumentInfo) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/documents`;
    return http.post(url, docInfo, this.token);
  }

  public async updateDocumentNumber(docID: string, docNumber: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/documents`;
    return http.post(
      url,
      { _id: docID, documentNumber: docNumber },
      this.token
    );
  }

  public async verifyLectureWithOTP(data: { otp: string }) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/verify-lecture-otp`;
    return http.post(url, data, this.token);
  }

  public async sendLectureOTP() {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/send-lecture-otp`;
    return http.post(url, { source: 'web' }, this.token);
  }

  public async getSingleContractDetails(id: string, overrideToken?: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/contract/${id}`;
    return http.get(url, overrideToken || this.token);
  }
  public async downloadContractPdf(id: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/contract/${id}/pdf/`;
    return http.download(url, this.token, 'application/pdf');
  }

  public async getExamList() {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/course/exams/`;
    return http.get(url, this.token);
  }

  public async verifyDocument(data: {
    userId: string;
    type: string;
    isApproved: boolean;
  }) {
    const url = `${this.apiDomain}/${serviceEndpoint}/approve-noc`;

    return http.put(url, data, this.token);
  }
  public async updateLectureLanguge(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student`;
    return http.put(url, data, this.token);
  }

  public async getRefundDetails(id: string, overrideToken?: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/refund-details/${id}`;
    return http.get(url, overrideToken || this.token);
  }

  public async getCorporateRefundDetails(id: string, overrideToken?: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/corporate-refund-details/${id}`;
    return http.get(url, overrideToken || this.token);
  }

  public async getClearanceCertificateDetails(
    id: string,
    overrideToken?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/clearance-certificate/${id}`;
    return http.get(url, overrideToken || this.token);
  }
  public async bookAppointment(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/book-an-appointment`;

    return http.post(url, data);
  }
  public async updateLocation(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student`;
    return http.put(url, data, this.token);
  }

  public async postCustomerFeedback(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/customer-feedback`;

    return http.post(url, data);
  }

  public async createRtaTestRequest(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/rta-test-requests/create`;

    return http.post(url, data, this.token);
  }

  public async getRtaTestRequests() {
    const url = `${this.apiDomain}/${serviceEndpoint}/rta-test-requests/view-all`;

    return http.get(url, this.token);
  }

  public async getRtaTestRequestsById(rtaTestRequestId: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/rta-test-requests/view/${rtaTestRequestId}`;

    return http.get(url, this.token);
  }

  public async getRtaComments(rtaTestRequestId: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/rta-test-comments/${rtaTestRequestId}`;

    return http.get(url, this.token);
  }

  public async createComment(rtaTestRequestId: string, message: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/rta-test-comments/create`;

    const payload = { rtaTestRequestId, message };

    return http.post(url, payload, this.token);
  }

  public async getRTAAddonDetails(userId: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/additional-addon/${userId}`;

    return http.get(url, this.token);
  }

  public async getMyReferralReport(id: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/referral-bonus/user/${id}`;
    return http.get(url, this.token);
  }

  public async readEmirateId(request: any, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${this.apiDomain}/${serviceEndpoint}/upload`;
    return http.uploadfile(url, formData, this.token);
  }

  public async getRenewedDate(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/get-renewed-date`;
    return http.post(url, data, this.token);
  }

  public async getCollectedPaymentDetails(filterData: any, token: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/csr/collection-report/getList`;
    return http.post(url, filterData, token || this.token);
  }

  public async getSalesmanCollectedPaymentDetails(
    filterData: any,
    token: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/salesman/collected-report/getList`;
    return http.post(url, filterData, token || this.token);
  }

  public async getSalesmanCollectingPaymentDetails(
    filterData: any,
    token: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/salesman/collecting-report/getList`;
    return http.post(url, filterData, token || this.token);
  }

  public async getAllCollectedPaymentDetails(filterData: any, token: string) {
    const url = `${this.apiDomain}/user/v1/all-collection-downloadble-data`;
    return http.post(url, filterData, token || this.token);
  }

  public async getFeedbackConfiguration(
    data: object,
    id?: string,
    token?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/studentfeedbacks/get-student-configuration/${id}`;
    return http.post(url, data, token || this.token);
  }

  public async submitStudentFeedback(
    data: object,
    id?: string,
    token?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/studentfeedbacks/submit-student-feedback/${id}`;
    return http.post(url, data, token || this.token);
  }

  public async skipStudentfeedback(data: object, token?: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/studentfeedbacks/skip-student-feedback`;
    return http.post(url, data, token || this.token);
  }
  public async getGeneralFeedbackConfiguration() {
    const url = `${this.apiDomain}/${serviceEndpoint}/studentfeedbacks/get-student-general-configuration`;
    return http.get(url, this.token);
  }
  public async getGeneralFeedbackListByUser(userId: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student-feedback/report/general-feedback-list?userId=${userId}`;
    return http.get(url, this.token);
  }
}
