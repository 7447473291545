import React from 'react';

export const CloseIcon = (props: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.514709"
        y="16.071"
        width="22"
        height="2"
        transform="rotate(-45 0.514709 16.071)"
        fill="#161616"
      />
      <rect
        x="16.0711"
        y="17.4853"
        width="22"
        height="2"
        transform="rotate(-135 16.0711 17.4853)"
        fill="#161616"
      />
    </svg>
  );
};
