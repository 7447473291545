import { FBIcon } from './fb';
import { LinkedinIcon } from './linkedin';
import { InstaIcon } from './insta';
import { TwitterIcon } from './twitter';
import { LiveChatIcon } from './livechat';
import { CallIcon } from './call';
import { DropdownIcon } from './dropdown';
import { SelectDropdownIcon } from './selectDropdown';
import { HamburgerIcon } from './hamburger';
import { CloseIcon } from './close';
import { WhatsAppIcon } from './whatsapp';
import { CarIcon } from './car';
import { MotorbikeIcon } from './motorbike';
import { HeavybusIcon } from './heavybus';
import { HeavytruckIcon } from './heavytruck';
import { HeavyforkliftIcon } from './heavyforklift';
import { PersonalInfoIcon } from './personal-details';
import { CourseTypeIcon } from './course-type';
import { PaymentPlanIcon } from './choose-payment-plan';
import { PaymentMethodIcon } from './choose-payment-method';

export {
  FBIcon,
  LinkedinIcon,
  InstaIcon,
  TwitterIcon,
  LiveChatIcon,
  CallIcon,
  DropdownIcon,
  SelectDropdownIcon,
  HamburgerIcon,
  CloseIcon,
  WhatsAppIcon,
  CarIcon,
  MotorbikeIcon,
  HeavybusIcon,
  HeavytruckIcon,
  HeavyforkliftIcon,
  PersonalInfoIcon,
  CourseTypeIcon,
  PaymentPlanIcon,
  PaymentMethodIcon,
};
