import ApiService from './service';

import * as http from './http';

const curriculumEndPoint = 'curriculum/v1';

export default class CurriculumService extends ApiService {
  public async getAssessmentResultDetails(
    assessmentId: number | string,

    instanceCode: string
  ) {
    const url = `${this.apiDomain}/${curriculumEndPoint}/assessment-results/${assessmentId}?instanceCode=${instanceCode}`;

    return http.get(url, this.token);
  }
}
