/* eslint-disable react/jsx-no-target-blank */
/* eslint jsx-a11y/anchor-is-valid: "off" */

import React from 'react';
import { Link } from 'react-router-dom';
import {
  InstaIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsAppIcon,
} from '../../components/icons';
import './Footer.scss';
import logoFooter from '../../assets/images/logo-footer.svg';
import appStorePng from '../../assets/images/app_store.png';
import googlePlayPng from '../../assets/images/google_play.png';
import googlePlaySvg from '../../assets/images/google_play.svg';
import appStoreSvg from '../../assets/images/app_store.svg';

const Footer = () => {
  const domain = process.env.REACT_APP_CMS_DOMAIN;

  const menu = [
    {
      id: 1,
      title: 'quick links',
      detailedContent: null,
      SubMenu: [
        {
          id: 11,
          title: 'About Us',
          relativeUrl: '/about-us',
        },
        // {
        //   id: 11,
        //   title: 'News',
        //   relativeUrl: '/news',
        // },
        // {
        //   id: 11,
        //   title: 'Facilities',
        //   relativeUrl: '#',
        // },
        {
          id: 11,
          title: 'FAQs',
          relativeUrl: '/faq',
        },
        // {
        //   id: 11,
        //   title: 'Careers',
        //   relativeUrl: '#',
        // },
        {
          id: 11,
          title: 'Documents',
          relativeUrl: '/documents',
        },
        // {
        //   id: 11,
        //   title: 'Feedback',
        //   relativeUrl: '#',
        // },
        {
          id: 11,
          title: 'Terms of Use',
          relativeUrl: '/terms-of-use',
        },
        {
          id: 11,
          title: 'Privacy Policy',
          relativeUrl: '/privacy-policy',
        },
        {
          id: 11,
          title: 'Find Us',
          relativeUrl: '/find-us',
        },
      ],
    },
    {
      id: 1,
      title: 'courses',
      detailedContent: null,
      SubMenu: [
        {
          id: 11,
          title: 'Car Course',
          relativeUrl: '/car-course',
        },
        {
          id: 11,
          title: 'Motorcycle Course',
          relativeUrl: '/bike-course',
        },
        {
          id: 11,
          title: 'Heavy Bus Course',
          relativeUrl: '/heavy-bus-course',
        },
        {
          id: 11,
          title: 'Heavy Truck Course',
          relativeUrl: '/heavy-truck-course',
        },
        {
          id: 11,
          title: 'Forklift Course ',
          relativeUrl: '/heavy-forklift-course',
        },
      ],
    },
  ];

  const socialMedia = [
    {
      title: 'Instagram',
      icon: 'instagram',
      url: 'https://www.instagram.com/ecodriveuae/',
    },
    {
      title: 'Whats App',
      icon: 'whatsapp',
      url: 'https://wa.me/+971503506107',
    },
    {
      title: 'Linkedin',
      icon: 'linkedin',
      url: 'https://www.linkedin.com/company/ecodrivecareers/',
    },
    {
      title: 'Twitter',
      icon: 'twitter',
      url: 'https://twitter.com/ecodriveuae',
    },
  ];

  const renderFooterItems = (menuItem: any) => {
    return (
      <>
        {menuItem?.detailedContent !== null && (
          <p className="mb-1 mt-3">{menuItem?.detailedContent}</p>
        )}

        {menuItem?.SubMenu?.length !== 0 && (
          <ul
            className={`lg:text-muted text-primary lg:text-lg text-sm lg:font-light font-medium lg:mb-0 mb-7 ${
              menuItem?.SubMenu?.length > 5 ? 'columns-2' : ''
            }`}
          >
            {menuItem?.SubMenu.map((subMenuItem: any, index: number) => {
              return (
                <li className="lg:mb-2 mb-2.5" key={'footer-submenu-' + index}>
                  {/* <Url item={subMenuItem} /> */}
                  {subMenuItem.relativeUrl && (
                    <a href={domain + subMenuItem?.relativeUrl} target="_blank">
                      {subMenuItem?.title}
                    </a>
                  )}
                  {/* {subMenuItem.absoluteUrl && (
                    <a href={subMenuItem?.absoluteUrl}>{subMenuItem?.title}</a>
                  )} */}
                </li>
              );
            })}
          </ul>
        )}
      </>
    );
  };

  return (
    <>
      <div className="bg-white pt-2 pb-6 lg:block hidden address">
        <div className="py-14 container flex justify-between">
          <div className="flex items-center">
            <div className="w-[70px] h-[70px] bg-primary rounded-full flex items-center justify-center">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28 13.3333C28 22.6666 16 30.6666 16 30.6666C16 30.6666 4 22.6666 4 13.3333C4 10.1507 5.26428 7.09847 7.51472 4.84803C9.76516 2.59759 12.8174 1.33331 16 1.33331C19.1826 1.33331 22.2348 2.59759 24.4853 4.84803C26.7357 7.09847 28 10.1507 28 13.3333Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 17.3333C18.2091 17.3333 20 15.5425 20 13.3333C20 11.1242 18.2091 9.33331 16 9.33331C13.7909 9.33331 12 11.1242 12 13.3333C12 15.5425 13.7909 17.3333 16 17.3333Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="ltr:pl-5 rtl:pr-5">
              <h6 className="text-base text-muted font-light mb-0">ADDRESS</h6>
              <h5 className="text-[21px] text-primary font-semibold mb-0">
                Industrial CIty, Dubai, UAE
              </h5>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-[70px] h-[70px] bg-primary rounded-full flex items-center justify-center">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33341 5.33331H26.6667C28.1334 5.33331 29.3334 6.53331 29.3334 7.99998V24C29.3334 25.4666 28.1334 26.6666 26.6667 26.6666H5.33341C3.86675 26.6666 2.66675 25.4666 2.66675 24V7.99998C2.66675 6.53331 3.86675 5.33331 5.33341 5.33331Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M29.3334 8L16.0001 17.3333L2.66675 8"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="ltr:pl-5 rtl:pr-5">
              <h6 className="text-base text-muted font-light mb-0">EMAIL</h6>
              <a
                href="mailto: info@ecodrive.ae"
                className="text-[21px] text-primary font-semibold mb-0"
              >
                info@ecodrive.ae
              </a>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-[70px] h-[70px] bg-primary rounded-full flex items-center justify-center">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5583 5.83335C18.6979 6.05568 19.7451 6.61299 20.5661 7.43394C21.387 8.2549 21.9443 9.30216 22.1667 10.4417M17.5583 1.16669C19.9258 1.4297 22.1335 2.48989 23.819 4.1732C25.5044 5.8565 26.5673 8.06286 26.8333 10.43M25.6667 19.74V23.24C25.668 23.5649 25.6014 23.8866 25.4713 24.1843C25.3411 24.482 25.1502 24.7492 24.9108 24.9689C24.6713 25.1885 24.3887 25.3557 24.0809 25.4598C23.7731 25.5639 23.4469 25.6026 23.1233 25.5734C19.5333 25.1833 16.0848 23.9565 13.055 21.9917C10.2361 20.2005 7.84623 17.8106 6.055 14.9917C4.08331 11.9481 2.85629 8.48285 2.47334 4.87669C2.44418 4.55406 2.48252 4.22891 2.58592 3.92192C2.68932 3.61492 2.8555 3.33282 3.0739 3.09358C3.29229 2.85433 3.55811 2.66318 3.85443 2.5323C4.15074 2.40141 4.47107 2.33366 4.795 2.33335H8.295C8.86119 2.32778 9.41009 2.52828 9.83939 2.89748C10.2687 3.26667 10.5491 3.77937 10.6283 4.34002C10.7761 5.4601 11.05 6.55987 11.445 7.61835C11.602 8.03593 11.6359 8.48975 11.5429 8.92605C11.4498 9.36234 11.2337 9.76282 10.92 10.08L9.43834 11.5617C11.0992 14.4825 13.5175 16.9009 16.4383 18.5617L17.92 17.08C18.2372 16.7663 18.6377 16.5502 19.074 16.4571C19.5103 16.3641 19.9641 16.3981 20.3817 16.555C21.4402 16.95 22.5399 17.224 23.66 17.3717C24.2267 17.4516 24.7443 17.7371 25.1143 18.1738C25.4843 18.6104 25.6809 19.1679 25.6667 19.74Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="ltr:pl-5 rtl:pr-5">
              <h6 className="text-base text-muted font-light mb-0">CALL</h6>
              <a
                href="tel:600595959"
                className="text-[21px] text-primary font-semibold mb-0"
              >
                600595959
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-white lg:pb-16 lg:pt-0 pt-9 lg:border-t-0 border-t border-t-slate-200 lg:block hidden">
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="lg:col-span-3 col-span-full lg:mb-0 mb-7">
              <Link to="/">
                <img src={logoFooter} width={228} height={32} />
              </Link>
            </div>
            {menu?.map((menuItem, index) => {
              return (
                <div
                  className={`lg:col-span-3 ${
                    index !== 0
                      ? 'lg:ltr:pl-8 lg:rtl:pr-8 col-span-6'
                      : 'xl:ltr:pr-5 xl:rtl:pl-5 lg:ltr:pl-2 lg:rtl:pr-2 col-span-full'
                  }`}
                  key={'menu-' + index}
                >
                  <div className="lg:mb-7 mb-2">
                    <h4 className="lg:text-xl text-base lg:uppercase capitalize text-primary font-bold inline-block relative mb-0 pb-1.5 lg:after:inline-block after:hidden after:w-4/5 after:h-0.5 after:bg-[#8A8C8B] after:absolute after:top-full ltr:after:left-0 rtl:after:right-0">
                      {menuItem?.title}
                    </h4>
                  </div>
                  {renderFooterItems(menuItem)}
                </div>
              );
            })}
            <div className="lg:col-span-3 col-span-6 ltr:pl-2 rtl:pr-2">
              <div className="lg:mb-7 mb-2">
                <h4 className="lg:text-xl text-base lg:uppercase capitalize text-primary font-bold inline-block relative mb-0 pb-1.5 lg:after:inline-block after:hidden after:w-4/5 after:h-0.5 after:bg-[#8A8C8B] after:absolute after:top-full ltr:after:left-0 rtl:after:right-0">
                  Download <span className="lg:inline hidden">the</span> App
                </h4>
              </div>
              <div className="lg:flex hidden">
                <Link to={'#'}>
                  <a className="inline-block ltr:mr-1.5 rtl:ml-1.5">
                    <img src={appStorePng} width={143} height={52} />
                  </a>
                </Link>
                <Link to={'#'}>
                  <a>
                    <img src={googlePlayPng} width={167} height={53} />
                  </a>
                </Link>
              </div>
              <div className="lg:hidden">
                <Link to={'#'}>
                  <a className="inline-block ltr:mr-1.5 rtl:ml-1.5">
                    <img src={googlePlaySvg} width={132} height={39} />
                  </a>
                </Link>
                <Link to={'#'}>
                  <a className="inline-block ltr:mr-1.5 rtl:ml-1.5">
                    <img src={appStoreSvg} width={132} height={39} />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="lg:bg-primary bg-[#F9F9F9] lg:py-5 pt-10 pb-8 lg:px-0 px-4 lg:border-t-0 border-t border-t-slate-200 socialmedia lg:block hidden">
        <ul className="flex items-center lg:justify-center text-white">
          {/* {data?.socialMedia?.items.map((followItem, index) => { */}
          {socialMedia.map((followItem, index) => {
            return (
              <li
                className="d-inline-block lg:px-3.5 lg:mr-0 mr-3 text-lg font-light"
                key={'followItem' + index}
              >
                <a
                  href={`${followItem?.url}`}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-baseline"
                >
                  <span className="rounded lg:bg-transparent bg-primary lg:w-auto lg:h-auto w-8 h-8 flex items-center justify-center">
                    {followItem.icon === 'instagram' && (
                      <InstaIcon
                        width={17}
                        height={18}
                        className="lg:ltr:mr-2.5 lg:rtl:ml-2.5 lg:top-0.5 relative"
                      />
                    )}
                    {followItem.icon === 'whatsapp' && (
                      <WhatsAppIcon className="lg:ltr:mr-2.5 lg:rtl:ml-2.5 relative lg:top-0.5" />
                    )}
                    {followItem.icon === 'linkedin' && (
                      <LinkedinIcon
                        className="lg:ltr:mr-2.5 lg:rtl:ml-2.5"
                        width={15}
                        height={16}
                      />
                    )}
                    {followItem.icon === 'twitter' && (
                      <TwitterIcon
                        className="lg:ltr:mr-2.5 lg:rtl:ml-2.5"
                        width={18}
                        height={16}
                      />
                    )}
                  </span>
                  <span className="lg:block hidden">{followItem.title}</span>
                </a>
              </li>
            );
          })}
        </ul>
        <div className="lg:hidden mt-6 text-sm text-primary font-medium">
          <p className="mb-4">
            Eco Drive, Driving Institute, 40,12
            <br /> Street, Dubai — 2GIS
          </p>
          <a
            href="mailto: info@ecodrive.ae"
            className="mb-4 inline-flex items-center"
          >
            <svg
              width="19"
              height="16"
              viewBox="0 0 19 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-3"
            >
              <path
                d="M18.8019 2.69116L18.0482 1.9375L11.067 8.91871C10.1944 9.79136 8.72672 9.79136 7.85407 8.91871L0.872856 1.97717L0.119202 2.73082L5.31544 7.92706L0.119202 13.1233L0.872856 13.877L6.0691 8.68071L7.10041 9.71203C7.73507 10.3467 8.56805 10.7037 9.4407 10.7037C10.3134 10.7037 11.1463 10.3467 11.781 9.71203L12.8123 8.68071L18.0086 13.877L18.7622 13.1233L13.566 7.92706L18.8019 2.69116Z"
                fill="#161616"
              />
              <path
                d="M17.334 15.2257H1.66597C0.753655 15.2257 0 14.4721 0 13.5598V2.37398C0 1.46166 0.753655 0.708008 1.66597 0.708008H17.334C18.2463 0.708008 19 1.46166 19 2.37398V13.5598C19 14.4721 18.2463 15.2257 17.334 15.2257ZM1.6263 1.77899C1.30898 1.77899 1.07098 2.01698 1.07098 2.33431V13.5201C1.07098 13.8374 1.30898 14.0754 1.6263 14.0754H17.2944C17.6117 14.0754 17.8497 13.8374 17.8497 13.5201V2.33431C17.8497 2.01698 17.6117 1.77899 17.2944 1.77899H1.6263Z"
                fill="#161616"
              />
            </svg>
            info@ecodrive.ae
          </a>
          <br />
          <a href="tel: 600 595 959" className="inline-flex items-center">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-3"
            >
              <path
                d="M12.5652 14.5582C12.5652 14.5582 14.168 13.6406 14.5875 13.4202C15.0059 13.1989 15.4394 13.1431 15.7026 13.3038C16.1012 13.5476 19.4484 15.7742 19.7302 15.9714C20.012 16.1689 20.1478 16.7329 19.7603 17.2855C19.3743 17.8381 17.5939 20.0232 16.8394 19.9998C16.0838 19.9753 12.9421 19.9064 7.01764 13.9803C1.09441 8.05614 1.02434 4.91357 1.0002 4.15791C0.976053 3.40265 3.16105 1.62177 3.71363 1.23543C4.267 0.849487 4.83185 0.99476 5.02819 1.26631C5.25144 1.57546 7.45226 4.91199 7.69451 5.29437C7.86037 5.55562 7.79941 5.99144 7.57814 6.41024C7.35805 6.82983 6.44052 8.43259 6.44052 8.43259C6.44052 8.43259 7.08731 9.53579 9.27467 11.7228C11.4624 13.9102 12.5652 14.5582 12.5652 14.5582Z"
                stroke="#161616"
                stroke-miterlimit="10"
              />
            </svg>
            600 595 959
          </a>
        </div>
      </div>
      <div className="lg:hidden hidden bg-[#F9F9F9] py-4 text-sm text-primary font-medium text-center border-t border-t-slate-200 copyright">
        <p>© 2022 Ecodrive. All Rights Reserved.</p>
      </div>
    </>
    // <footer id="footer" className="footer-txt">
    //   <div className="container">
    //     <div className="footer-top">
    //       <div className="row gutter-10">
    //         <div className="col-xl-2 col-lg-3 col-sm-4 col-12 mb-lg-0 mb-3">
    //           <ul className="footer-menu-list m-0 p-0">
    //             <li>
    //               <span> Quick Links</span>
    //               <ul>
    //                 <li>
    //                   {' '}
    //                   <a href={`${domain}/who-we-are/`}>Who we are</a>
    //                 </li>
    //                 <li className="mega-menu-link">
    //                   {' '}
    //                   <a href={`${domain}/contact-us/`}>Contact Us</a>
    //                 </li>
    //                 <li className="mega-menu-link">
    //                   {' '}
    //                   <a href={`${domain}/faq/`}>FAQ</a>
    //                 </li>
    //                 <li>
    //                   {' '}
    //                   <a href={`${domain}/term-of-use/`}>Terms of use</a>
    //                 </li>
    //                 <li>
    //                   {' '}
    //                   <a href={`${domain}/privacy-policy/`}>Privacy Policy</a>
    //                 </li>
    //               </ul>
    //             </li>
    //           </ul>
    //         </div>
    //         <div className="col-xl-6 col-lg-5 col-sm-8 col-12 mb-4">
    //           <div className="pr-xl-5">
    //             <span className="text-uppercase font-weight-medium mb-3 pb-1 text-body d-block font-base">
    //               Address
    //             </span>
    //             <div className="address row">
    //               <div className="col-sm-6 col-12">
    //                 <p>Ecodrive Driving Institute</p>
    //               </div>
    //               <div className="col-sm-6 col-12">
    //                 <p className="m-0">
    //                   <a href="tel:+971 4 3583005" className="footer-txt">
    //                     Tel : +971 000000
    //                   </a>
    //                 </p>
    //                 <p className="m-0">Fax : +971 000000</p>
    //                 <p className="m-0">
    //                   <a
    //                     href="mailto: info@ecodrive.com"
    //                     className="footer-txt"
    //                   >
    //                     info@ecodrive.com
    //                   </a>
    //                 </p>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-lg-4 col-sm-8 col-12 last-box">
    //           <div className="social-media">
    //             <span className="text-uppercase font-weight-medium mb-3 pb-1 text-body font-base d-block">
    //               Follow Us On
    //             </span>
    //             <ul className="list-unstyled social-links list-inline p-0 m-0">
    //               <li className="pr-4 mr-2 mb-0">
    //                 <a href="" target="_blank">
    //                   <i className="icon-facebook-logo" />
    //                 </a>
    //               </li>
    //               <li className="pr-4 mr-2 mb-0">
    //                 <a href="" target="_blank">
    //                   <i className="icon-twitter" />
    //                 </a>
    //               </li>
    //               <li className="pr-4 mr-2 mb-0">
    //                 <a href="" target="_blank">
    //                   <i className="icon-instagram ml-md-2 m-0" />
    //                 </a>
    //               </li>
    //               <li className="mb-0">
    //                 <a href="" target="_blank">
    //                   <i className="icon-youtube" />
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="footer-btm border-top">
    //       <div className="copyright-blk text-center py-4">
    //         <div className="copy-right pr-4">
    //           <div className="wrapper">
    //             {' '}
    //             <span className="co-rg-txt">
    //               © 2019 eco drive.&nbsp; All rights reserved.
    //             </span>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </footer>
  );
};

export default Footer;
