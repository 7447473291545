import React from 'react';

export const TwitterIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 1.53887C15.4118 1.80027 14.7788 1.97659 14.1146 2.0555C14.7928 1.6496 15.3127 1.00664 15.558 0.239646C14.9237 0.616001 14.2215 0.889094 13.4735 1.03652C12.8749 0.398761 12.0217 0 11.0775 0C9.26488 0 7.79522 1.46938 7.79522 3.28199C7.79522 3.53884 7.82413 3.7892 7.88032 4.02982C5.1521 3.89279 2.73309 2.58643 1.11402 0.600739C0.831456 1.08523 0.669712 1.64895 0.669712 2.25099C0.669712 3.38947 1.24913 4.39417 2.12996 4.98256C1.59211 4.96535 1.08576 4.81761 0.643079 4.57179C0.642754 4.58543 0.642754 4.59939 0.642754 4.61335C0.642754 6.2032 1.77431 7.52938 3.27613 7.83137C3.00071 7.90605 2.71068 7.94632 2.41122 7.94632C2.19946 7.94632 1.99387 7.92586 1.79348 7.88722C2.21148 9.19099 3.42358 10.1402 4.85979 10.1665C3.73635 11.0468 2.32126 11.5715 0.783062 11.5715C0.518361 11.5715 0.256907 11.556 0 11.5254C1.4531 12.4571 3.17837 13 5.03193 13C11.0701 13 14.3715 7.99925 14.3715 3.66224C14.3715 3.52001 14.3686 3.3781 14.3624 3.23717C15.0036 2.77542 15.5602 2.19708 16 1.53887Z"
        fill="white"
      />
    </svg>
  );
};
