import React from 'react';

export const WhatsAppIcon = (props: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.2956 5.17869C16.8407 4.16803 16.1876 3.26065 15.3584 2.47869C14.5292 1.7041 13.5679 1.0918 12.4966 0.663934C11.3886 0.221311 10.2145 0 9.00371 0C7.79294 0 6.61886 0.221311 5.51082 0.663934C4.43948 1.0918 3.47821 1.69672 2.64901 2.47869C1.81982 3.26065 1.16675 4.16803 0.711798 5.17869C0.242167 6.22623 0 7.34754 0 8.49836C0 10.5123 0.748502 12.4451 2.1207 13.9795L1.38691 18L5.29068 16.2516C6.45742 16.7533 7.69754 17.0041 8.99636 17.0041C10.2071 17.0041 11.3812 16.7828 12.4892 16.3402C13.5606 15.9123 14.5219 15.3074 15.351 14.5254C16.1802 13.7434 16.8333 12.8361 17.2883 11.8254C17.7579 10.7779 18.0001 9.65655 18.0001 8.50573C18.0001 7.35491 17.7652 6.2336 17.2956 5.17869ZM9.01105 15.9934C7.78561 15.9934 6.60418 15.7426 5.51082 15.241L5.30537 15.1451L2.71505 16.3033L3.19936 13.6549L3.02326 13.4705C1.7171 12.0984 1.00532 10.3279 1.00532 8.49098C1.00532 4.35983 4.59359 0.995902 9.01105 0.995902C13.4212 0.995902 17.0168 4.35983 17.0168 8.49098C17.0168 12.6221 13.4212 15.9934 9.01105 15.9934Z"
        fill="white"
      />
      <path
        d="M12.6506 10.1878C12.269 9.99595 11.9901 9.87791 11.792 9.80414C11.6673 9.75988 11.3737 9.6271 11.271 9.70825C10.9481 9.97382 10.6033 10.7263 10.2364 10.8664C9.32644 10.6894 8.48258 10.0623 7.82216 9.42054C7.52864 9.14021 6.98563 8.34349 6.86822 8.12955C6.84621 7.90824 7.24248 7.61316 7.33054 7.44348C7.78549 6.92709 7.44059 6.6025 7.38188 6.38857C7.27915 6.16726 7.10305 5.76889 6.94895 5.4443C6.81687 5.23037 6.78752 4.91316 6.55271 4.79513C5.55474 4.27873 4.98237 5.31152 4.74755 5.85004C3.33132 9.28037 11.8434 15.8091 13.5751 11.3091C13.6632 10.9181 13.6265 10.7705 13.4944 10.5935C13.2376 10.4164 12.9294 10.3353 12.6506 10.1878Z"
        fill="white"
      />
    </svg>
  );
};
