import React from 'react';

import '../Input.scss';
// import './Checkbox.scss';

interface CustomProps {
  default?: boolean;
  id: string;
  label: string;
  onChange: (value: boolean) => void;
  gap?: 'sm' | 'md' | 'lg';
  size?: string;
  error?: string;
}
interface CustomState {
  value: any;
}

class Checkbox extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    gap: 'lg',
    size: '2',
    default: false,
    error: '',
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    this.setState((prevState) => {
      return {
        value: !prevState.value,
      };
    });
    this.props.onChange(!this.state.value);
  }

  render() {
    const { id, gap, label, error, size } = this.props;
    const sizeClass = size ? 'custom-checkbox--sm' : '';

    const inputId = `checkbox-${id}`;
    return (
      <div className={`Input Input--gap-${gap}`}>
        <div className={`custom-checkbox ${sizeClass}`}>
          <input
            type="checkbox"
            id={id}
            name={id}
            checked={this.state.value}
            onChange={this.handleChange}
          />
          <label className="custom-checkbox__label" htmlFor={inputId}>
            <span className="custom__label-left">{label}</span>
          </label>
        </div>
        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}

// Checkbox.propTypes = {
//   default: PropTypes.bool,
//   id: PropTypes.string.isRequired,
//   label: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
//   gap: PropTypes.oneOf(['sm', 'md', 'lg']),
//   size: PropTypes.string,
//   error: PropTypes.string,
// };

// Checkbox.defaultProps = {
//   gap: 'lg',
//   size: '2',
//   default: false,
//   error: '',
// };

export default Checkbox;
