import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Button from '../../components/button';
import ThankYouImage from '../../assets/images/thankyou-img.png';
import { RouteKeys } from '../route-keys';
// actions
import { actions as authUserActions } from '../../store/reducers/authUser';
// import { useGTagConversionTracker } from '../../hooks/gtag';

const ThankYouPage: React.SFC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchProfile = (onSuccess?: () => void) =>
    dispatch(authUserActions.fetchProfile(onSuccess));

  const goBackHome = async () => {
    setLoading(true);
    await fetchProfile(() => {
      history.replace(RouteKeys.Dashboard);
      setLoading(false);
    });
  };

  // eslint-disable-next-line class-methods-use-this
  const renderImage = () => {
    return (
      <div className="Thankyou-img">
        <img src={ThankYouImage} className="img-fluid" alt="thankyou" />
      </div>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  const renderHeader = () => {
    return (
      <h3 className="text-2xl md:text-[32px] font-bold pt-10">Thank you</h3>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  const renderDescription = () => {
    return (
      <p className="text-muted text-sm md:text-base pt-6 mb-10">
        For registering with Eco Drive Driving Institute.
        <br />
        We will contact you soon once our executives verified your details.
      </p>
    );
  };

  const renderBackButton = () => {
    return (
      <button
        type="button"
        className="btn btn-primary block w-full md:max-w-[320px] mx-auto"
        onClick={goBackHome}
        disabled={loading}
      >
        {loading ? 'Please wait' : 'Back to Home'}
      </button>
    );
  };

  const renderBody = () => {
    return (
      <div className="pt-[110px] md:pt-[200px] pb-[85px] text-center">
        <div className="container">
          {/* {renderImage()} */}
          {renderHeader()}
          {renderDescription()}
          {renderBackButton()}
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      {renderBody()}
      {/* <Footer /> */}
    </>
  );
};

export default ThankYouPage;
