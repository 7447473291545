import React from 'react';

export const HamburgerIcon = (props: any) => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="22" height="2" fill="white" />
      <rect y="7" width="22" height="2" fill="white" />
      <rect y="14" width="22" height="2" fill="white" />
    </svg>
  );
};
