import React, { Component } from 'react';

import './PaymentOption.scss';

interface CustomProps {
  label: string;
  selected?: boolean;
  id: string;
  icon: string;
  disabled?: boolean;
  primarySubText?: boolean;
  onSelect?: () => void;
}

class PaymentOption extends Component<CustomProps> {
  static defaultProps: Partial<CustomProps> = {
    selected: false,
    id: 'pay-online',
    disabled: false,
  };
  renderInput() {
    return (
      <input
        type="radio"
        id={this.props.id}
        name="payment"
        disabled={this.props.disabled}
        checked={this.props.selected}
        value={'payment'}
      />
    );
  }

  renderLabelText = () => {
    return (
      <p className="text-sm text-primary font-normal">
        {/* <i className={`${this.props.icon} PaymentRadio__icon`} /> */}
        {this.props.label}
      </p>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  renderLabelIcon() {
    return (
      <span
        className={`ltr:pr-3 ltr:pl-2 rtl:pl-3 rtl:pr-2 
        // ${this.props.selected ? 'PaymentRadio__round__selected' : ''}
        `}
      >
        {/* <span className="circle-outline">
          <span className="circle-fill" />
        </span> */}
        {this.props.icon === 'ecodrive' ? (
          <svg width="24" height="31" viewBox="0 0 24 31" fill="none">
            <path
              d="M7.24206 13.9273C5.92056 13.9273 4.83691 12.8437 4.83691 11.5223C4.83691 10.2008 5.92056 9.11719 7.24206 9.11719C8.56356 9.11719 9.64719 10.2008 9.64719 11.5223C9.64719 12.8437 8.56356 13.9273 7.24206 13.9273ZM7.24206 10.0686C6.44916 10.0686 5.78841 10.7029 5.78841 11.5223C5.78841 12.3151 6.42273 12.9759 7.24206 12.9759C8.06139 12.9759 8.69571 12.3416 8.69571 11.5223C8.66928 10.7294 8.03496 10.0686 7.24206 10.0686Z"
              fill="#8A8C8B"
            ></path>
            <path
              d="M9.17115 19.6899H5.28593C5.04806 19.6899 4.83662 19.5049 4.81019 19.267C4.7309 18.4742 4.123 17.8663 3.3301 17.787C3.09223 17.7606 2.90723 17.5491 2.90723 17.3113V5.2595C2.90723 5.02163 3.09223 4.8102 3.3301 4.78377C4.123 4.70448 4.7309 4.09661 4.81019 3.30373C4.83662 3.06587 5.04806 2.88086 5.28593 2.88086H9.17115C9.40902 2.88086 9.62046 3.06587 9.64689 3.30373C9.72618 4.09661 10.3341 4.70448 11.127 4.78377C11.3648 4.8102 11.5499 5.02163 11.5499 5.2595V9.62033C11.5499 9.88463 11.3384 10.0961 11.0741 10.0961C10.8098 10.0961 10.5984 9.88463 10.5984 9.62033V5.65594C9.72617 5.41807 9.039 4.73091 8.7747 3.83232H5.68238C5.44451 4.70448 4.75732 5.39164 3.8587 5.65594V16.9413C4.73089 17.1791 5.41808 17.8663 5.68238 18.7649H8.80113C9.01257 18.0249 9.54118 17.3906 10.2812 17.0734C10.5191 16.9677 10.8098 17.0734 10.9155 17.3113C11.0213 17.5491 10.9155 17.8398 10.6777 17.9456C10.1226 18.1834 9.7526 18.712 9.67331 19.2935C9.62045 19.5049 9.43545 19.6899 9.17115 19.6899Z"
              fill="#8A8C8B"
            ></path>
            <path
              d="M11.5499 22.5971H0.475738C0.211437 22.5971 0 22.3856 0 22.1213V0.475727C0 0.211434 0.211437 0 0.475738 0H13.9551C14.2194 0 14.4308 0.211434 14.4308 0.475727V13.2411C14.4308 13.5054 14.2194 13.7168 13.9551 13.7168C13.6908 13.7168 13.4793 13.5054 13.4793 13.2411V0.951455H0.977915V21.6192H11.5499C11.8142 21.6192 12.0257 21.8306 12.0257 22.0949C12.0257 22.3592 11.8142 22.5971 11.5499 22.5971Z"
              fill="#8A8C8B"
            ></path>
            <path
              d="M21.1708 28.8356C21.0915 28.8356 21.0122 28.8091 20.9593 28.7827C20.7215 28.677 20.6158 28.3863 20.7479 28.1484L21.6994 26.2191C21.6994 26.2191 21.6994 26.1926 21.7258 26.1926C21.8844 25.9283 22.1487 24.2897 22.1487 23.8404V18.6867C22.1487 17.6031 21.7523 16.5459 21.0122 15.753L16.0698 10.4408L13.6911 8.74928C13.4796 8.5907 13.4268 8.29998 13.5854 8.08854C13.744 7.87711 14.0347 7.82425 14.2461 7.98283L16.6513 9.70073C16.6777 9.72716 16.7041 9.75359 16.7305 9.75359L21.6994 15.0923C22.598 16.0702 23.1002 17.3388 23.1002 18.6867V23.8404C23.1002 24.2369 22.8623 26.1133 22.5716 26.6684L21.6201 28.5713C21.5144 28.7563 21.3558 28.8356 21.1708 28.8356Z"
              fill="#8A8C8B"
            ></path>
            <path
              d="M22.6242 30.7656C22.3599 30.7656 22.1485 30.5541 22.1485 30.2898V28.8362H11.5765V30.2898C11.5765 30.5541 11.365 30.7656 11.1007 30.7656C10.8364 30.7656 10.625 30.5541 10.625 30.2898V28.3605C10.625 28.0962 10.8364 27.8848 11.1007 27.8848H22.6507C22.915 27.8848 23.1264 28.0962 23.1264 28.3605V30.2898C23.1 30.5541 22.8885 30.7656 22.6242 30.7656Z"
              fill="#8A8C8B"
            ></path>
            <path
              d="M12.5282 28.8353C12.2639 28.8353 12.0524 28.6238 12.0524 28.3595V27.1174C11.5767 26.6152 10.123 24.8709 10.123 23.0737V22.1222C10.123 21.8579 10.3345 21.6465 10.5988 21.6465C10.8631 21.6465 11.0745 21.8579 11.0745 22.1222V23.0737C11.0745 24.7651 12.8453 26.5623 12.8453 26.5888C12.9246 26.6681 12.9775 26.8002 12.9775 26.9323V28.386C13.0039 28.6238 12.7925 28.8353 12.5282 28.8353Z"
              fill="#8A8C8B"
            ></path>
            <path
              d="M16.3604 25.0036C16.2811 25.0036 16.1754 24.9772 16.0961 24.9243C13.3738 23.1271 13.7438 19.8499 13.9289 18.8984L10.8101 15.5419C10.4137 15.1455 10.1758 14.5904 10.1758 14.009C10.1758 13.4276 10.3872 12.899 10.8101 12.4761C11.2066 12.0797 11.7616 11.8418 12.343 11.8418C12.9245 11.8418 13.4531 12.0532 13.876 12.4761L18.3955 16.9691C18.5805 17.1541 18.5805 17.4712 18.3955 17.6562C18.2105 17.8412 17.8934 17.8412 17.7083 17.6562L13.1888 13.1633C12.9509 12.9254 12.6602 12.8197 12.343 12.8197C12.0259 12.8197 11.7087 12.9518 11.4973 13.1633C11.2594 13.4011 11.1537 13.6918 11.1537 14.009C11.1537 14.3262 11.2858 14.6433 11.4973 14.8547C11.4973 14.8547 11.4973 14.8547 11.4973 14.8812L14.7746 18.4227C14.8803 18.5548 14.9332 18.7134 14.8803 18.872C14.8539 19.0041 14.0346 22.4135 16.5983 24.1314C16.8097 24.29 16.889 24.5807 16.7304 24.7922C16.6776 24.9243 16.519 25.0036 16.3604 25.0036Z"
              fill="#8A8C8B"
            ></path>
          </svg>
        ) : (
          <svg width="31" height="31" viewBox="0 0 31 31" fill="none">
            <path
              d="M28.8325 30.7592H5.78199C4.72583 30.7592 3.85449 29.8879 3.85449 28.8318V14.4157C3.85449 13.3596 4.72583 12.4883 5.78199 12.4883H28.8325C29.8887 12.4883 30.76 13.3596 30.76 14.4157V28.8318C30.76 29.9143 29.8887 30.7592 28.8325 30.7592ZM5.75557 13.4652C5.22749 13.4652 4.80504 13.8876 4.80504 14.4157V28.8318C4.80504 29.3599 5.22749 29.7823 5.75557 29.7823H28.8061C29.3342 29.7823 29.7567 29.3599 29.7567 28.8318V14.4157C29.7567 13.8876 29.3342 13.4652 28.8061 13.4652H5.75557Z"
              fill="#464646"
            ></path>
            <path
              d="M12.0142 20.6742H8.15924C7.36713 20.6742 6.70703 20.0405 6.70703 19.222V16.8194C6.70703 16.0273 7.34072 15.3672 8.15924 15.3672H12.0142C12.8063 15.3672 13.4664 16.0009 13.4664 16.8194V19.222C13.4664 20.0405 12.8063 20.6742 12.0142 20.6742ZM8.15924 16.3441C7.8952 16.3441 7.68396 16.5553 7.68396 16.8194V19.222C7.68396 19.4861 7.8952 19.6973 8.15924 19.6973H12.0142C12.2782 19.6973 12.4895 19.4861 12.4895 19.222V16.8194C12.4895 16.5553 12.2782 16.3441 12.0142 16.3441H8.15924Z"
              fill="#464646"
            ></path>
            <path
              d="M9.61045 25.0033H7.20769C6.94365 25.0033 6.73242 24.792 6.73242 24.528C6.73242 24.264 6.94365 24.0527 7.20769 24.0527H9.61045C9.87448 24.0527 10.0857 24.264 10.0857 24.528C10.0857 24.792 9.87448 25.0033 9.61045 25.0033Z"
              fill="#464646"
            ></path>
            <path
              d="M15.3663 25.0033H12.9635C12.6995 25.0033 12.4883 24.792 12.4883 24.528C12.4883 24.264 12.6995 24.0527 12.9635 24.0527H15.3663C15.6303 24.0527 15.8416 24.264 15.8416 24.528C15.868 24.792 15.6303 25.0033 15.3663 25.0033Z"
              fill="#464646"
            ></path>
            <path
              d="M21.6241 25.0033H19.2214C18.9573 25.0033 18.7461 24.792 18.7461 24.528C18.7461 24.264 18.9573 24.0527 19.2214 24.0527H21.6241C21.8882 24.0527 22.0994 24.264 22.0994 24.528C22.0994 24.792 21.8882 25.0033 21.6241 25.0033Z"
              fill="#464646"
            ></path>
            <path
              d="M27.38 25.0033H24.9772C24.7132 25.0033 24.502 24.792 24.502 24.528C24.502 24.264 24.7132 24.0527 24.9772 24.0527H27.38C27.644 24.0527 27.8552 24.264 27.8552 24.528C27.8816 24.792 27.644 25.0033 27.38 25.0033Z"
              fill="#464646"
            ></path>
            <path
              d="M12.0132 27.8802H7.20769C6.94365 27.8802 6.73242 27.669 6.73242 27.4049C6.73242 27.1409 6.94365 26.9297 7.20769 26.9297H12.0132C12.2772 26.9297 12.4885 27.1409 12.4885 27.4049C12.4885 27.669 12.2772 27.8802 12.0132 27.8802Z"
              fill="#464646"
            ></path>
            <path
              d="M27.3803 27.8802H16.3171C16.053 27.8802 15.8418 27.669 15.8418 27.4049C15.8418 27.1409 16.053 26.9297 16.3171 26.9297H27.3803C27.6443 26.9297 27.8555 27.1409 27.8555 27.4049C27.8555 27.669 27.6443 27.8802 27.3803 27.8802Z"
              fill="#464646"
            ></path>
            <path
              d="M1.92748 18.2709C0.871323 18.2709 0 17.3996 0 16.3435V1.92742C0 0.871299 0.871323 0 1.92748 0H24.978C26.0342 0 26.9055 0.871299 26.9055 1.92742V10.5876C26.9055 10.8517 26.6943 11.0629 26.4302 11.0629C26.1662 11.0629 25.955 10.8517 25.955 10.5876V1.92742C25.955 1.39936 25.5325 0.976917 25.0044 0.976917H1.95389C1.42581 0.976917 1.00334 1.39936 1.00334 1.92742V16.3435C1.00334 16.8716 1.42581 17.294 1.95389 17.294C2.21793 17.294 2.42916 17.5052 2.42916 17.7693C2.42916 18.0333 2.19152 18.2709 1.92748 18.2709Z"
              fill="#464646"
            ></path>
            <path
              d="M26.4307 6.25911H4.33075C4.06671 6.25911 3.85547 6.04789 3.85547 5.78386C3.85547 5.51983 4.06671 5.30859 4.33075 5.30859H26.4307C26.6948 5.30859 26.906 5.51983 26.906 5.78386C26.906 6.04789 26.6948 6.25911 26.4307 6.25911Z"
              fill="#464646"
            ></path>
          </svg>
        )}
      </span>
    );
  }

  renderLabel() {
    return (
      <label
        className="bg-slate-50 block px-[30px] py-5 rounded-sm w-full mode"
        htmlFor={this.props.id}
        onClick={(e) => {
          e.preventDefault();
          if (this.props.onSelect) {
            this.props.onSelect();
          }
        }}
      >
        <div className="flex items-center">
          {this.renderLabelIcon()}
          {this.renderLabelText()}
        </div>
      </label>
    );
  }

  render() {
    return (
      <div className="custom-radio payment-method">
        {this.renderInput()}
        {this.renderLabel()}
      </div>
    );
  }
}

// PaymentOption.propTypes = {
//   label: PropTypes.string.isRequired,
//   selected: PropTypes.bool,
//   id: PropTypes.string,
//   icon: PropTypes.string.isRequired,
//   disabled: PropTypes.bool,
// };

export default PaymentOption;
