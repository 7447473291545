import React from 'react';
import ContentLoader from 'react-content-loader';
import './CourseTypeLoader.scss';

const CourseTypeLoader = () => (
  <div className="CourseTypeLoader">
    <ContentLoader
      height={300}
      width={700}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="-4" y="15" rx="5" ry="5" width="700" height="300" />
    </ContentLoader>
  </div>
);

export default CourseTypeLoader;
