import React, { Component } from 'react';

import './PaymentPlanOption.scss';

interface CustomProps {
  selected: boolean;
  onSelect?: () => void;
  id: string;
  name: string;
  description?: string;
  totalAmount?: number;
  totalRtaAmount?: number;
  totalEdsAmount?: number;
  showTooltip?: boolean;
  primary?: boolean;
}
class PaymentPlanOption extends Component<CustomProps> {
  renderInput() {
    return (
      <input
        type="radio"
        id={this.props.id}
        checked={this.props.selected}
        onClick={this.props.onSelect}
        onChange={() => {
          return;
        }}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderLabelIcon() {
    return (
      <div className="PaymentPlanRadio__round">
        <span className="circle-fill">
          <i className="icon-tick" />
        </span>
      </div>
    );
  }

  renderLabelText() {
    return (
      <div className="">
        <h4 className="text-base text-primary">{this.props.name}</h4>
        <p
          className={`text-base font-normal text-muted sm:leading-[12px] pt-1 
          // ${this.props.primary ? '--primary' : ''}
          `}
        >
          {this.props.description}
        </p>
      </div>
    );
  }

  renderTooltipRtaAmount() {
    return (
      <span className="PaymentPlan__tooltip-item">
        RTA Fee -{' '}
        <span className="PaymentPlan__tooltip-price">
          AED {(this.props.totalRtaAmount || 0).toFixed(2)}
        </span>
      </span>
    );
  }

  renderTooltipEdsAmount() {
    return (
      <span className="PaymentPlan__tooltip-item">
        EDC Fee -{' '}
        <span className="PaymentPlan__tooltip-price">
          AED {(this.props.totalEdsAmount || 0).toFixed(2)}
        </span>
      </span>
    );
  }

  renderAmountTooltip() {
    return (
      <div className="PaymentPlan__tooltip Show">
        {this.renderTooltipRtaAmount()}
        {this.renderLabelAmount()}
      </div>
    );
  }

  renderLabelAmount() {
    return (
      <span className="text-lg font-semibold text-primary">
        AED {this.props.totalAmount}
        {/* {this.props.showTooltip && this.renderAmountTooltip()} */}
      </span>
    );
  }

  renderLabel() {
    return (
      <label
        className="paymentlabel bg-slate-50 block p-6 rounded-md sm:rounded-sm mb-5 mt-3"
        htmlFor={this.props.id}
      >
        {/* {this.renderLabelIcon()} */}
        <div className="flex justify-between items-center">
          {this.renderLabelText()}
          {this.renderLabelAmount()}
        </div>
      </label>
    );
  }

  render() {
    return (
      <div className="custom-radio payment">
        {this.renderInput()}
        {this.renderLabel()}
      </div>
    );
  }
}

// PaymentPlanOption.propTypes = {
//   selected: PropTypes.bool.isRequired,
//   onSelect: PropTypes.func.isRequired,
//   id: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
//   description: PropTypes.string.isRequired,
//   totalAmount: PropTypes.number.isRequired,
//   totalRtaAmount: PropTypes.number.isRequired,
//   totalEdsAmount: PropTypes.number.isRequired,
//   showTooltip: PropTypes.bool,
//   primary: PropTypes.bool,
// };

export default PaymentPlanOption;
