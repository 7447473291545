import React from 'react';

export const LinkedinIcon = (props: any) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.13076 4.38696H0V14.0381H3.13076V4.38696Z" fill="white" />
      <path
        d="M11.6584 4.50015C11.6251 4.48973 11.5936 4.47835 11.5587 4.46852C11.5166 4.45893 11.4747 4.45092 11.4318 4.44408C11.2657 4.41067 11.0834 4.38699 10.87 4.38699C9.04492 4.38699 7.88706 5.71809 7.50579 6.23214V4.38696H4.375V14.0381H7.50576V8.77386C7.50576 8.77386 9.87161 5.46966 10.87 7.89635V14.0381H13.9999V7.52538C13.9999 6.06708 13.0032 4.85196 11.6584 4.50015Z"
        fill="white"
      />
      <path
        d="M3.0628 1.53537C3.0628 2.38348 2.3771 3.0708 1.53154 3.0708C0.685916 3.0708 0.000244141 2.38345 0.000244141 1.53537C0.000244141 0.687433 0.685916 0 1.53154 0C2.3771 0 3.0628 0.687433 3.0628 1.53537Z"
        fill="white"
      />
    </svg>
  );
};
