import React from 'react';

export const FBIcon = (props: any) => {
  return (
    <svg
      width="7"
      height="16"
      viewBox="0 0 7 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 5.18087H4.61605V3.52027C4.61605 2.89663 5.00521 2.75123 5.27931 2.75123C5.55279 2.75123 6.96164 2.75123 6.96164 2.75123V0.00960457L4.64473 0C2.07275 0 1.48744 2.04479 1.48744 3.35334V5.18087H0V8.00596H1.48744C1.48744 11.6315 1.48744 16 1.48744 16H4.61605C4.61605 16 4.61605 11.5885 4.61605 8.00596H6.72715L7 5.18087Z"
        fill="white"
      />
    </svg>
  );
};
