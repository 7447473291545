// import { Dispatch } from 'redux';

// import api from '../../api';
import { types as commontypes } from './common';
import { Dispatch } from 'react';
import api from '../../api';
import {
  StudentSchedule,
  TimeSlot,
  ScheduleOption,
  ScheduleStatus,
  ScheduleResourceEnum,
  TheoryLectureScheduleOption,
  MockTestSimulatorScheduleOption,
} from '../../models/schedule';
import {
  FindScheduleRequestModel,
  ScheduleRequestModel,
  RateScheduleRequestModel,
  CommentScheduleRequestModel,
  FindTheoryLectureScheduleRequestModel,
  TheoryLectureScheduleRequestModel,
  FindMockTestSimulatorScheduleRequestModel,
  MockTestSimulatorScheduleRequestModel,
} from '../../api/models/schedule';
import { Timings } from '../../models/date';
import { toastr } from 'react-redux-toastr';

export const types = {
  // get list for dashboard
  GET_DASHBOARD_SCHEDULES_REQUEST: 'SCHEDULES/GET_DASHBOARD_SCHEDULES_REQUEST',
  GET_DASHBOARD_SCHEDULES_SUCCESS: 'SCHEDULES/GET_DASHBOARD_SCHEDULES_SUCCESS',
  GET_DASHBOARD_SCHEDULES_ERROR: 'SCHEDULES/GET_DASHBOARD_SCHEDULES_ERROR',

  // get by month
  GET_SCHEDULES_REQUEST: 'SCHEDULES/GET_SCHEDULES_REQUEST',
  GET_SCHEDULES_SUCCESS: 'SCHEDULES/GET_SCHEDULES_SUCCESS',
  GET_SCHEDULES_ERROR: 'SCHEDULES/GET_SCHEDULES_ERROR',

  // get time slots
  GET_SCHEDULE_TIME_SLOTS_REQUEST: 'SCHEDULES/GET_SCHEDULE_TIME_SLOTS_REQUEST',
  GET_SCHEDULE_TIME_SLOTS_SUCCESS: 'SCHEDULES/GET_SCHEDULE_TIME_SLOTS_SUCCESS',
  GET_SCHEDULE_TIME_SLOTS_ERROR: 'SCHEDULES/GET_SCHEDULE_TIME_SLOTS_ERROR',

  // get time slots
  GET_PRACTICAL_TRAINING_TIME_SLOTS_REQUEST:
    'SCHEDULES/GET_PRACTICAL_TRAINING_TIME_SLOTS_REQUEST',
  GET_PRACTICAL_TRAINING_TIME_SLOTS_SUCCESS:
    'SCHEDULES/GET_PRACTICAL_TRAINING_TIME_SLOTS_SUCCESS',
  GET_PRACTICAL_TRAINING_TIME_SLOTS_ERROR:
    'SCHEDULES/GET_PRACTICAL_TRAINING_TIME_SLOTS_ERROR',

  // get details
  GET_SCHEDULE_DETAILS_REQUEST: 'SCHEDULES/GET_SCHEDULE_DETAILS_REQUEST',
  GET_SCHEDULE_DETAILS_SUCCESS: 'SCHEDULES/GET_SCHEDULE_DETAILS_SUCCESS',
  GET_SCHEDULE_DETAILS_ERROR: 'SCHEDULES/GET_SCHEDULE_DETAILS_ERROR',

  // cancel schedule
  CANCEL_SCHEDULE_REQUEST: 'SCHEDULES/CANCEL_SCHEDULE_REQUEST',
  CANCEL_SCHEDULE_SUCCESS: 'SCHEDULES/CANCEL_SCHEDULE_SUCCESS',
  CANCEL_SCHEDULE_ERROR: 'SCHEDULES/CANCEL_SCHEDULE_ERROR',

  // find schedules
  FIND_SCHEDULES_REQUEST: 'SCHEDULES/FIND_SCHEDULES_REQUEST',
  FIND_SCHEDULES_SUCCESS: 'SCHEDULES/FIND_SCHEDULES_SUCCESS',
  FIND_SCHEDULES_ERROR: 'SCHEDULES/FIND_SCHEDULES_ERROR',
  CLOSE_SCHEDULE_OPTIONS: 'SCHEDULES/CLOSE_SCHEDULE_OPTIONS',
  // request
  SCHEDULE_REQUEST: 'SCHEDULES/SCHEDULE_REQUEST',
  SCHEDULE_SUCCESS: 'SCHEDULES/SCHEDULE_SUCCESS',
  SCHEDULE_ERROR: 'SCHEDULES/SCHEDULE_ERROR',

  // find schedules
  FIND_THEORY_LECTURE_SCHEDULES_REQUEST:
    'SCHEDULES/FIND_THEORY_LECTURE_SCHEDULES_REQUEST',
  FIND_THEORY_LECTURE_SCHEDULES_SUCCESS:
    'SCHEDULES/FIND_THEORY_LECTURE_SCHEDULES_SUCCESS',
  FIND_THEORY_LECTURE_SCHEDULES_ERROR:
    'SCHEDULES/FIND_THEORY_LECTURE_SCHEDULES_ERROR',
  CLOSE_THEORY_LECTURE_SCHEDULE_OPTIONS:
    'SCHEDULES/CLOSE_THEORY_LECTURE_SCHEDULE_OPTIONS',

  // request
  THEORY_LECTURE_SCHEDULE_REQUEST: 'SCHEDULES/THEORY_LECTURE_SCHEDULE_REQUEST',
  THEORY_LECTURE_SCHEDULE_SUCCESS: 'SCHEDULES/THEORY_LECTURE_SCHEDULE_SUCCESS',
  THEORY_LECTURE_SCHEDULE_ERROR: 'SCHEDULES/THEORY_LECTURE_SCHEDULE_ERROR',

  // find schedules
  FIND_MOCK_TEST_SCHEDULES_REQUEST:
    'SCHEDULES/FIND_MOCK_TEST_SCHEDULES_REQUEST',
  FIND_MOCK_TEST_SCHEDULES_SUCCESS:
    'SCHEDULES/FIND_MOCK_TEST_SCHEDULES_SUCCESS',
  FIND_MOCK_TEST_SCHEDULES_ERROR: 'SCHEDULES/FIND_MOCK_TEST_SCHEDULES_ERROR',
  CLOSE_MOCK_TEST_SCHEDULE_OPTIONS:
    'SCHEDULES/CLOSE_MOCK_TEST_SCHEDULE_OPTIONS',

  // request
  MOCK_TEST_SCHEDULE_REQUEST: 'SCHEDULES/MOCK_TEST_SCHEDULE_REQUEST',
  MOCK_TEST_SCHEDULE_SUCCESS: 'SCHEDULES/MOCK_TEST_SCHEDULE_SUCCESS',
  MOCK_TEST_SCHEDULE_ERROR: 'SCHEDULES/MOCK_TEST_SCHEDULE_ERROR',

  // find schedules
  FIND_SIMULATOR_TRAINING_SCHEDULES_REQUEST:
    'SCHEDULES/FIND_SIMULATOR_TRAINING_SCHEDULES_REQUEST',
  FIND_SIMULATOR_TRAINING_SCHEDULES_SUCCESS:
    'SCHEDULES/FIND_SIMULATOR_TRAINING_SCHEDULES_SUCCESS',
  FIND_SIMULATOR_TRAINING_SCHEDULES_ERROR:
    'SCHEDULES/FIND_SIMULATOR_TRAINING_SCHEDULES_ERROR',
  CLOSE_SIMULATOR_TRAINING_SCHEDULE_OPTIONS:
    'SCHEDULES/CLOSE_SIMULATOR_TRAINING_SCHEDULE_OPTIONS',

  // request
  SIMULATOR_TRAINING_SCHEDULE_REQUEST:
    'SCHEDULES/SIMULATOR_TRAINING_SCHEDULE_REQUEST',
  SIMULATOR_TRAINING_SCHEDULE_SUCCESS:
    'SCHEDULES/SIMULATOR_TRAINING_SCHEDULE_SUCCESS',
  SIMULATOR_TRAINING_SCHEDULE_ERROR:
    'SCHEDULES/SIMULATOR_TRAINING_SCHEDULE_ERROR',

  // comment schedule
  COMMENT_SCHEDULE_REQUEST: 'SCHEDULES/COMMENT_SCHEDULE_REQUEST',
  COMMENT_SCHEDULE_SUCCESS: 'SCHEDULES/FIND_SCHEDULE_SUCCESS',
  COMMENT_SCHEDULE_ERROR: 'SCHEDULES/COMMENT_SCHEDULE_ERROR',

  // RATE schedule
  RATE_SCHEDULE_REQUEST: 'SCHEDULES/RATE_SCHEDULE_REQUEST',
  RATE_SCHEDULE_SUCCESS: 'SCHEDULES/RATE_SCHEDULE_SUCCESS',
  RATE_SCHEDULE_ERROR: 'SCHEDULES/RATE_SCHEDULE_ERROR',
};

// initial state
export interface ScheduleState {
  dashboardList: {
    isLoading: boolean;
    isLoaded: boolean;
    errorMessage?: string;
    schedulesByDate?: StudentSchedule[];
  };
  list: {
    isLoading: boolean;
    isLoaded: boolean;
    errorMessage?: string;
    schedules?: StudentSchedule[];
    selectedDate?: Date;
  };
  details: {
    id?: string;
    isLoading: boolean;
    isLoaded: boolean;
    errorMessage?: string;
    schedule?: StudentSchedule;
  };
  cancel: {
    id?: string;
    isLoading: boolean;
    isLoaded: boolean;
    errorMessage?: string;
  };
  request: {
    isLoading: boolean;
    isLoaded: boolean;
    timeSlots?: TimeSlot[];
    isUpdating: boolean;
    errorMessage?: string;
    branchId?: string;
    instanceId?: string;
    data?: FindScheduleRequestModel;
    scheduleOptions?: ScheduleOption[];
  };
  theoryLectureScheduleRequest: {
    isLoading: boolean;
    isLoaded: boolean;
    timeSlots?: TimeSlot[];
    isUpdating: boolean;
    errorMessage?: string;
    instanceId?: string;
    data?: FindTheoryLectureScheduleRequestModel;
    scheduleOptions?: TheoryLectureScheduleOption[];
  };
  mockTestScheduleRequest: {
    isLoading: boolean;
    isLoaded: boolean;
    timeSlots?: TimeSlot[];
    isUpdating: boolean;
    errorMessage?: string;
    instanceId?: string;
    data?: FindMockTestSimulatorScheduleRequestModel;
    scheduleOptions?: MockTestSimulatorScheduleOption[];
  };

  simulatorTrainingScheduleRequest: {
    isLoading: boolean;
    isLoaded: boolean;
    timeSlots?: TimeSlot[];
    isUpdating: boolean;
    errorMessage?: string;
    instanceId?: string;
    data?: FindMockTestSimulatorScheduleRequestModel;
    scheduleOptions?: MockTestSimulatorScheduleOption[];
  };
}

const initialState: ScheduleState = {
  dashboardList: {
    isLoading: false,
    isLoaded: false,
  },
  list: {
    isLoading: false,
    isLoaded: false,
  },
  details: {
    isLoading: false,
    isLoaded: false,
  },
  request: {
    branchId: undefined,
    instanceId: undefined,
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
  },
  theoryLectureScheduleRequest: {
    instanceId: undefined,
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
  },
  mockTestScheduleRequest: {
    instanceId: undefined,
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
  },
  simulatorTrainingScheduleRequest: {
    instanceId: undefined,
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
  },
  cancel: {
    isLoading: false,
    isLoaded: false,
    id: undefined,
  },
};

// reducer

export default (
  state: ScheduleState = initialState,
  action: any
): ScheduleState => {
  switch (action.type) {
    case commontypes.RESET_DATA:
      return { ...initialState };
    // get schedules
    case types.GET_SCHEDULES_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          isLoaded: false,
          schedules: [],
          selectedDate: action.data.monthDate,
        },
      };
    case types.GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          isLoaded: true,
          schedules: action.data,
        },
      };
    case types.GET_SCHEDULES_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          isLoaded: true,
          errorMessage: action.errorMessage,
        },
      };
    // find schedules
    case types.FIND_SCHEDULES_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          isUpdating: true,
          data: action.data,
          scheduleOptions: [],
        },
      };
    case types.FIND_SCHEDULES_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          isUpdating: false,
          scheduleOptions: action.data,
        },
      };
    case types.FIND_SCHEDULES_ERROR:
      return {
        ...state,
        request: {
          ...state.request,
          isUpdating: false,
          scheduleOptions: [],
          errorMessage: action.errorMessage,
        },
      };
    case types.CLOSE_SCHEDULE_OPTIONS:
      return {
        ...state,
        request: {
          ...state.request,
          scheduleOptions: [],
        },
      };
    // find schedules
    case types.FIND_THEORY_LECTURE_SCHEDULES_REQUEST:
      return {
        ...state,
        theoryLectureScheduleRequest: {
          ...state.theoryLectureScheduleRequest,
          isUpdating: true,
          data: action.data,
          scheduleOptions: [],
        },
      };
    case types.FIND_THEORY_LECTURE_SCHEDULES_SUCCESS:
      return {
        ...state,
        theoryLectureScheduleRequest: {
          ...state.theoryLectureScheduleRequest,
          isUpdating: false,
          scheduleOptions: action.data,
        },
      };
    case types.FIND_THEORY_LECTURE_SCHEDULES_ERROR:
      return {
        ...state,
        theoryLectureScheduleRequest: {
          ...state.theoryLectureScheduleRequest,
          isUpdating: false,
          scheduleOptions: [],
          errorMessage: action.errorMessage,
        },
      };
    case types.CLOSE_THEORY_LECTURE_SCHEDULE_OPTIONS:
      return {
        ...state,
        theoryLectureScheduleRequest: {
          ...state.theoryLectureScheduleRequest,
          scheduleOptions: [],
        },
      };

    // find schedules
    case types.FIND_MOCK_TEST_SCHEDULES_REQUEST:
      return {
        ...state,
        mockTestScheduleRequest: {
          ...state.mockTestScheduleRequest,
          isUpdating: true,
          data: action.data,
          scheduleOptions: [],
        },
      };
    case types.FIND_MOCK_TEST_SCHEDULES_SUCCESS:
      return {
        ...state,
        mockTestScheduleRequest: {
          ...state.mockTestScheduleRequest,
          isUpdating: false,
          scheduleOptions: action.data,
        },
      };
    case types.FIND_MOCK_TEST_SCHEDULES_ERROR:
      return {
        ...state,
        mockTestScheduleRequest: {
          ...state.mockTestScheduleRequest,
          isUpdating: false,
          scheduleOptions: [],
          errorMessage: action.errorMessage,
        },
      };
    case types.CLOSE_MOCK_TEST_SCHEDULE_OPTIONS:
      return {
        ...state,
        mockTestScheduleRequest: {
          ...state.mockTestScheduleRequest,
          scheduleOptions: [],
        },
      };

    // find schedules
    case types.FIND_SIMULATOR_TRAINING_SCHEDULES_REQUEST:
      return {
        ...state,
        simulatorTrainingScheduleRequest: {
          ...state.simulatorTrainingScheduleRequest,
          isUpdating: true,
          data: action.data,
          scheduleOptions: [],
        },
      };
    case types.FIND_SIMULATOR_TRAINING_SCHEDULES_SUCCESS:
      return {
        ...state,
        simulatorTrainingScheduleRequest: {
          ...state.simulatorTrainingScheduleRequest,
          isUpdating: false,
          scheduleOptions: action.data,
        },
      };
    case types.FIND_SIMULATOR_TRAINING_SCHEDULES_ERROR:
      return {
        ...state,
        simulatorTrainingScheduleRequest: {
          ...state.simulatorTrainingScheduleRequest,
          isUpdating: false,
          scheduleOptions: [],
          errorMessage: action.errorMessage,
        },
      };
    case types.CLOSE_SIMULATOR_TRAINING_SCHEDULE_OPTIONS:
      return {
        ...state,
        simulatorTrainingScheduleRequest: {
          ...state.simulatorTrainingScheduleRequest,
          scheduleOptions: [],
        },
      };

    // get slots
    case types.GET_SCHEDULE_TIME_SLOTS_REQUEST:
      return {
        ...state,
        theoryLectureScheduleRequest: {
          ...state.theoryLectureScheduleRequest,
          instanceId: action.data.instanceId,
          isLoading: true,
          isLoaded: false,
          timeSlots: [],
          scheduleOptions: [],
        },
        mockTestScheduleRequest: {
          ...state.mockTestScheduleRequest,
          instanceId: action.data.instanceId,
          isLoading: true,
          isLoaded: false,
          timeSlots: [],
          scheduleOptions: [],
        },
        simulatorTrainingScheduleRequest: {
          ...state.simulatorTrainingScheduleRequest,
          instanceId: action.data.instanceId,
          isLoading: true,
          isLoaded: false,
          timeSlots: [],
          scheduleOptions: [],
        },
      };
    case types.GET_SCHEDULE_TIME_SLOTS_SUCCESS:
      return {
        ...state,
        theoryLectureScheduleRequest: {
          ...state.theoryLectureScheduleRequest,
          isLoading: false,
          isLoaded: true,
          timeSlots: action.data,
          scheduleOptions: [],
        },
        mockTestScheduleRequest: {
          ...state.mockTestScheduleRequest,
          isLoading: false,
          isLoaded: true,
          timeSlots: action.data,
          scheduleOptions: [],
        },
        simulatorTrainingScheduleRequest: {
          ...state.simulatorTrainingScheduleRequest,
          isLoading: false,
          isLoaded: true,
          timeSlots: action.data,
          scheduleOptions: [],
        },
      };
    case types.GET_SCHEDULE_TIME_SLOTS_ERROR:
      return {
        ...state,
        theoryLectureScheduleRequest: {
          ...state.theoryLectureScheduleRequest,
          isLoading: false,
          isLoaded: true,
          errorMessage: action.errorMessage,
          scheduleOptions: [],
        },
        mockTestScheduleRequest: {
          ...state.mockTestScheduleRequest,
          isLoading: false,
          isLoaded: true,
          errorMessage: action.errorMessage,
          scheduleOptions: [],
        },
        simulatorTrainingScheduleRequest: {
          ...state.simulatorTrainingScheduleRequest,
          isLoading: false,
          isLoaded: true,
          errorMessage: action.errorMessage,
          scheduleOptions: [],
        },
      };
    // get practical training slots
    case types.GET_PRACTICAL_TRAINING_TIME_SLOTS_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          instanceId: action.data.instanceId,
          isLoading: true,
          isLoaded: false,
          timeSlots: [],
          scheduleOptions: [],
        },
      };
    case types.GET_PRACTICAL_TRAINING_TIME_SLOTS_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          isLoading: false,
          isLoaded: true,
          timeSlots: action.data,
          scheduleOptions: [],
        },
      };
    case types.GET_PRACTICAL_TRAINING_TIME_SLOTS_ERROR:
      return {
        ...state,
        request: {
          ...state.request,
          isLoading: false,
          isLoaded: true,
          errorMessage: action.errorMessage,
          scheduleOptions: [],
        },
      };
    // details
    case types.GET_SCHEDULE_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: true,
          isLoaded: false,
          schedule: undefined,
          id: action.data.id,
        },
      };
    case types.GET_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: false,
          isLoaded: true,
          schedule: action.data,
        },
      };
    case types.GET_SCHEDULE_DETAILS_ERROR:
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: false,
          isLoaded: true,
          errorMessage: action.errorMessage,
        },
      };
    // details
    case types.CANCEL_SCHEDULE_REQUEST:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          isLoading: true,
          isLoaded: false,
          id: action.data.id,
        },
      };
    case types.CANCEL_SCHEDULE_SUCCESS:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          isLoading: false,
          isLoaded: true,
        },
        list: {
          ...state.list,
          schedules: (state.list.schedules || []).filter(
            (sc) => sc._id !== (state.cancel || {}).id
          ),
        },
      };
    case types.CANCEL_SCHEDULE_ERROR:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          isLoading: false,
          isLoaded: true,
          errorMessage: action.errorMessage,
        },
      };
    // request
    case types.SCHEDULE_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          isUpdating: true,
          data: action.data,
        },
      };
    case types.SCHEDULE_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          isUpdating: false,
          data: undefined,
          scheduleOptions: [],
        },
      };
    case types.SCHEDULE_ERROR:
      return {
        ...state,
        request: {
          ...state.request,
          isUpdating: false,
          errorMessage: action.errorMessage,
        },
      };

    // request
    case types.THEORY_LECTURE_SCHEDULE_REQUEST:
      return {
        ...state,
        theoryLectureScheduleRequest: {
          ...state.theoryLectureScheduleRequest,
          isUpdating: true,
          data: action.data,
        },
      };
    case types.THEORY_LECTURE_SCHEDULE_SUCCESS:
      return {
        ...state,
        theoryLectureScheduleRequest: {
          ...state.theoryLectureScheduleRequest,
          isUpdating: false,
          data: undefined,
          scheduleOptions: [],
        },
      };
    case types.THEORY_LECTURE_SCHEDULE_ERROR:
      return {
        ...state,
        theoryLectureScheduleRequest: {
          ...state.theoryLectureScheduleRequest,
          isUpdating: false,
          errorMessage: action.errorMessage,
        },
      };

    // request
    case types.MOCK_TEST_SCHEDULE_REQUEST:
      return {
        ...state,
        mockTestScheduleRequest: {
          ...state.mockTestScheduleRequest,
          isUpdating: true,
          data: action.data,
        },
      };
    case types.MOCK_TEST_SCHEDULE_SUCCESS:
      return {
        ...state,
        mockTestScheduleRequest: {
          ...state.mockTestScheduleRequest,
          isUpdating: false,
          data: undefined,
          scheduleOptions: [],
        },
      };
    case types.MOCK_TEST_SCHEDULE_ERROR:
      return {
        ...state,
        mockTestScheduleRequest: {
          ...state.mockTestScheduleRequest,
          isUpdating: false,
          errorMessage: action.errorMessage,
        },
      };

    // request
    case types.SIMULATOR_TRAINING_SCHEDULE_REQUEST:
      return {
        ...state,
        theoryLectureScheduleRequest: {
          ...state.theoryLectureScheduleRequest,
          isUpdating: true,
          data: action.data,
        },
      };
    case types.SIMULATOR_TRAINING_SCHEDULE_SUCCESS:
      return {
        ...state,
        simulatorTrainingScheduleRequest: {
          ...state.simulatorTrainingScheduleRequest,
          isUpdating: false,
          data: undefined,
          scheduleOptions: [],
        },
      };
    case types.SIMULATOR_TRAINING_SCHEDULE_ERROR:
      return {
        ...state,
        simulatorTrainingScheduleRequest: {
          ...state.simulatorTrainingScheduleRequest,
          isUpdating: false,
          errorMessage: action.errorMessage,
        },
      };

    // get schedules bu date
    case types.GET_DASHBOARD_SCHEDULES_REQUEST:
      return {
        ...state,
        dashboardList: {
          ...state.dashboardList,
          isLoading: true,
          isLoaded: false,
          schedulesByDate: [],
        },
      };
    case types.GET_DASHBOARD_SCHEDULES_SUCCESS:
      return {
        ...state,
        dashboardList: {
          ...state.dashboardList,
          isLoading: false,
          isLoaded: true,
          schedulesByDate: (action.data || []).filter(
            (sc: StudentSchedule) => sc.status === ScheduleStatus.SCHEDULED
          ),
        },
      };
    case types.GET_DASHBOARD_SCHEDULES_ERROR:
      return {
        ...state,
        dashboardList: {
          ...state.dashboardList,
          isLoading: false,
          isLoaded: true,
          errorMessage: action.errorMessage,
        },
      };

    // comment
    case types.COMMENT_SCHEDULE_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          isUpdating: true,
        },
      };
    case types.COMMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          isUpdating: false,
        },
      };
    case types.COMMENT_SCHEDULE_ERROR:
      return {
        ...state,
        request: {
          ...state.request,
          isUpdating: false,
        },
      };

    // rate
    case types.RATE_SCHEDULE_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          isUpdating: true,
        },
      };
    case types.RATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          isUpdating: false,
        },
        details: {
          ...state.details,
          schedule: action.data,
        },
      };
    case types.RATE_SCHEDULE_ERROR:
      return {
        ...state,
        request: {
          ...state.request,
          isUpdating: false,
        },
      };
    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  getSchedules: (
    courseId: string,
    monthDate: Date,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.GET_SCHEDULES_REQUEST,
      data: { monthDate },
    });
    try {
      const response = await api.schedule.getSchedules(
        courseId,
        monthDate.getFullYear(),
        monthDate.getMonth()
      );
      dispatch({
        type: types.GET_SCHEDULES_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.GET_SCHEDULES_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to get schedules.',
      });
      if (onError) onError('Unable to get schedules.');
      // throw error;
    }
  },
  getTimeSlots: (
    instanceId: string,
    timeAllowed: Timings[],
    date: Date,
    resource: ScheduleResourceEnum,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.GET_SCHEDULE_TIME_SLOTS_REQUEST,
      data: { instanceId },
    });
    try {
      const response = await api.schedule.getTimeSlots(
        timeAllowed,
        date,
        resource
      );
      dispatch({
        type: types.GET_SCHEDULE_TIME_SLOTS_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.GET_SCHEDULE_TIME_SLOTS_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to get schedule configuration.',
      });
      if (onError) onError('Unable to get schedule configuration');
      // throw error;
    }
  },
  getPracticalTrainingTimeSlots: (
    data: {
      instanceId: string;
      instanceCode: string;
      licenseTypeId: string;
      branchId: string;
      instructorTypeId: string;
      courseTypeId: string;
      gearType: string;
    },
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    const {
      instanceId,
      instanceCode,
      licenseTypeId,
      branchId,
      instructorTypeId,
      courseTypeId,
      gearType,
    } = data;
    dispatch({
      type: types.GET_PRACTICAL_TRAINING_TIME_SLOTS_REQUEST,
      data: { instanceId, instanceCode },
    });
    try {
      const response = await api.schedule.getPracticalTrainingTimeSlots({
        instanceCode,
        licenseTypeId,
        branchId,
        instructorTypeId,
        courseTypeId,
        gearType,
      });
      dispatch({
        type: types.GET_PRACTICAL_TRAINING_TIME_SLOTS_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.GET_PRACTICAL_TRAINING_TIME_SLOTS_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to get schedule configuration.',
      });
      if (onError) onError('Unable to get schedule configuration');
      // throw error;
    }
  },
  cancelSchedule: (
    id: string,
    onSuccess?: (data: StudentSchedule) => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.CANCEL_SCHEDULE_REQUEST,
      data: { id },
    });
    try {
      const response = await api.schedule.cancelSchedule(id);
      dispatch({
        type: types.CANCEL_SCHEDULE_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      let message = 'Unable to cancel schedule.';
      if (error && error.response && error.response.status === 409) {
        message = error.response.data.message;
      }
      dispatch({
        type: types.CANCEL_SCHEDULE_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: message,
      });
      if (onError) onError(message);
    }
  },
  getScheduleDetails: (
    id: string,
    onSuccess?: (data: StudentSchedule) => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.GET_SCHEDULE_DETAILS_REQUEST,
      data: { id },
    });
    try {
      const response = await api.schedule.getScheduleDetails(id);
      dispatch({
        type: types.GET_SCHEDULE_DETAILS_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      dispatch({
        type: types.GET_SCHEDULE_DETAILS_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to get schedule details.',
      });
      if (onError) onError('Unable to get schedule  details.');
    }
  },
  schedule: (
    data: ScheduleRequestModel,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SCHEDULE_REQUEST,
      data,
    });
    try {
      const response = await api.schedule.schedule(data);
      dispatch({
        type: types.SCHEDULE_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.SCHEDULE_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to schedule.',
      });
      if (onError) onError('Unable to schedule');
      // throw error;
    }
  },
  findSchedules: (
    data: FindScheduleRequestModel,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.FIND_SCHEDULES_REQUEST,
      data,
    });
    try {
      const response = await api.schedule.findSchedule(data);
      dispatch({
        type: types.FIND_SCHEDULES_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
      if (!response.data || response.data.length === 0) {
        toastr.error('Sorry', 'No schedule available, please try again later');
      }
    } catch (error) {
      dispatch({
        type: types.FIND_SCHEDULES_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to find schedules.',
      });
      if (onError) onError('Unable to find schedules');
      // throw error;
    }
  },
  closeScheduleOptions: () => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.CLOSE_SCHEDULE_OPTIONS,
    });
  },
  findTheoryLectureSchedules: (
    data: FindTheoryLectureScheduleRequestModel,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.FIND_THEORY_LECTURE_SCHEDULES_REQUEST,
      data,
    });
    try {
      const response = await api.schedule.findTheoryLectureSchedule(data);
      dispatch({
        type: types.FIND_THEORY_LECTURE_SCHEDULES_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
      if (!response.data || response.data.length === 0) {
        toastr.error('Sorry', 'No schedule available, please try again later');
      }
    } catch (error) {
      dispatch({
        type: types.FIND_THEORY_LECTURE_SCHEDULES_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to find schedules.',
      });
      if (onError) onError('Unable to find schedules');
      // throw error;
    }
  },
  scheduleTheoryLecture: (
    data: TheoryLectureScheduleRequestModel,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.THEORY_LECTURE_SCHEDULE_REQUEST,
      data,
    });
    try {
      const response = await api.schedule.scheduleTheoryLecture(data);
      dispatch({
        type: types.THEORY_LECTURE_SCHEDULE_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.THEORY_LECTURE_SCHEDULE_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to schedule.',
      });
      if (onError) onError('Unable to schedule');
      // throw error;
    }
  },
  closeTheoryLectureScheduleOptions: () => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.CLOSE_THEORY_LECTURE_SCHEDULE_OPTIONS,
    });
  },
  findMockTestSchedules: (
    data: FindMockTestSimulatorScheduleRequestModel,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.FIND_MOCK_TEST_SCHEDULES_REQUEST,
      data,
    });
    try {
      const response = await api.schedule.findMockTestSchedule(data);
      dispatch({
        type: types.FIND_MOCK_TEST_SCHEDULES_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
      if (!response.data || response.data.length === 0) {
        toastr.error('Sorry', 'No schedule available, please try again later');
      }
    } catch (error) {
      dispatch({
        type: types.FIND_MOCK_TEST_SCHEDULES_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to find schedules.',
      });
      if (onError) onError('Unable to find schedules');
      // throw error;
    }
  },
  scheduleMockTest: (
    data: MockTestSimulatorScheduleRequestModel,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.MOCK_TEST_SCHEDULE_REQUEST,
      data,
    });
    try {
      const response = await api.schedule.scheduleMockTest(data);
      dispatch({
        type: types.MOCK_TEST_SCHEDULE_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.MOCK_TEST_SCHEDULE_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to schedule.',
      });
      if (onError) onError('Unable to schedule');
      // throw error;
    }
  },
  closeMockTestScheduleOptions: () => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.CLOSE_MOCK_TEST_SCHEDULE_OPTIONS,
    });
  },
  findSimulatorTrainingSchedules: (
    data: FindMockTestSimulatorScheduleRequestModel,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.FIND_SIMULATOR_TRAINING_SCHEDULES_REQUEST,
      data,
    });
    try {
      const response = await api.schedule.findSimulatorTrainingSchedule(data);
      dispatch({
        type: types.FIND_SIMULATOR_TRAINING_SCHEDULES_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
      if (!response.data || response.data.length === 0) {
        toastr.error('Sorry', 'No schedule available, please try again later');
      }
    } catch (error) {
      dispatch({
        type: types.FIND_SIMULATOR_TRAINING_SCHEDULES_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to find schedules.',
      });
      if (onError) onError('Unable to find schedules');
      // throw error;
    }
  },
  scheduleSimulatorTraining: (
    data: MockTestSimulatorScheduleRequestModel,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SIMULATOR_TRAINING_SCHEDULE_REQUEST,
      data,
    });
    try {
      const response = await api.schedule.scheduleSimulatorTraining(data);
      dispatch({
        type: types.SIMULATOR_TRAINING_SCHEDULE_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.SIMULATOR_TRAINING_SCHEDULE_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to schedule.',
      });
      if (onError) onError('Unable to schedule');
      // throw error;
    }
  },
  closeSimulatorTrainingScheduleOptions: () => async (
    dispatch: Dispatch<any>
  ) => {
    dispatch({
      type: types.CLOSE_SIMULATOR_TRAINING_SCHEDULE_OPTIONS,
    });
  },
  getSchedulesByDate: (
    courseId: string,
    startDate: Date,
    endDate?: Date,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void
  ) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.GET_DASHBOARD_SCHEDULES_REQUEST,
      data: { startDate },
    });
    try {
      const response = await api.schedule.getSchedulesByDate(
        courseId,
        startDate,
        endDate
      );
      dispatch({
        type: types.GET_DASHBOARD_SCHEDULES_SUCCESS,
        data: response.data,
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.GET_DASHBOARD_SCHEDULES_ERROR,
        data:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors,
        errorMessage: 'Unable to get schedules.',
      });
      if (onError) onError('Unable to get schedules.');
      // throw error;
    }
  },

  rateSchedule: (id: string, data: RateScheduleRequestModel) => async (
    dispatch: Dispatch<any>
  ) => {
    dispatch({
      type: types.RATE_SCHEDULE_REQUEST,
    });
    try {
      const response = await api.schedule.rateSchedule(id, data);
      dispatch({
        type: types.RATE_SCHEDULE_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'Sucessfully rated');
    } catch (error) {
      dispatch({
        type: types.RATE_SCHEDULE_ERROR,
      });
      toastr.error('Error', 'Unable to rate schedule');
    }
  },
  commentSchedule: (id: string, data: CommentScheduleRequestModel) => async (
    dispatch: Dispatch<any>
  ) => {
    dispatch({
      type: types.COMMENT_SCHEDULE_REQUEST,
    });
    try {
      const response = await api.schedule.commentSchedule(id, data);
      toastr.success('Success', 'Sucessfully commented');
      dispatch({
        type: types.COMMENT_SCHEDULE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: types.COMMENT_SCHEDULE_ERROR,
      });

      toastr.error('Error', 'Unable to comment on schedule');
    }
  },
};
