import React from 'react';
// import PropTypes from 'prop-types';

import '../../form-group/Input.scss';
// import './MilestoneCheckbox.scss';

interface CustomProps {
  selected?: boolean;
  disabled?: boolean;
  label: string;
  totalAmount: number;
  gap?: 'sm' | 'md' | 'lg';
  size?: string;
  error?: string;
  id: string;
  onChange: (value: boolean) => void;
}

class MilestoneCheckbox extends React.Component<CustomProps> {
  renderLabel(id: string) {
    return (
      <label className="flex justify-between" htmlFor={id}>
        <span className="custom__label-left">{this.props.label}</span>
        <span className="custom__label-right">
          AED {this.props.totalAmount.toFixed(2)}
        </span>
      </label>
    );
  }

  renderError() {
    return (
      this.props.error && (
        <span className="Input__error">{this.props.error}</span>
      )
    );
  }

  renderInput(id: string) {
    return (
      <input
        type="checkbox"
        id={id}
        name={id}
        checked={this.props.selected}
        onChange={(e: any) => this.props.onChange(e.target.checked)}
        disabled={this.props.disabled}
      />
    );
  }

  render() {
    const sizeClass = this.props.size ? 'custom-checkbox--sm' : '';

    const id = `checkbox-${this.props.id}`;
    return (
      // <div className={`Input Input--gap-${this.props.gap}`}>
      <>
        <div className={`custom-checkbox mb-2 ${sizeClass}`}>
          {this.renderInput(id)}
          {this.renderLabel(id)}
        </div>
        {this.renderError()}
      </>
    );
  }
}

// MilestoneCheckbox.propTypes = {
//   selected: PropTypes.bool,
//   disabled: PropTypes.bool,
//   label: PropTypes.string,
//   totalAmount: PropTypes.number,
//   gap: PropTypes.oneOf(['sm', 'md', 'lg']),
//   size: PropTypes.string,
//   error: PropTypes.string,
//   id: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
// };

// MilestoneCheckbox.defaultProps = {
//   selected: false,
//   label: null,
//   totalAmount: 0,
//   gap: 'lg',
//   size: '2',
//   error: '',
//   disabled: false,
// };

export default MilestoneCheckbox;
